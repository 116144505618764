import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { displayNameMap } from '@itorum/models';

@Injectable({
  providedIn: 'root',
})
export class LayoutService implements OnDestroy {
  private destroyed$ = new Subject();
  currentScreenSize: string;
  private _currentScreenSize$ = new BehaviorSubject<string>(null);

  get currentScreenSize$(): Observable<string> {
    return this._currentScreenSize$.asObservable();
  }

  // displayNameMap = new Map([
  //   [Breakpoints.XSmall, "XSmall"],
  //   [Breakpoints.Small, "Small"],
  //   [Breakpoints.Medium, "Medium"],
  //   [Breakpoints.Large, "Large"],
  //   [Breakpoints.XLarge, "XLarge"]
  // ]);

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = displayNameMap.get(query) ?? 'Unknown';
            this._currentScreenSize$.next(this.currentScreenSize);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
