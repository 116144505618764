import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RoomStateQuery } from 'app/+conference/pages/room/state/room-state.query';
import { RoomStateService } from 'app/+conference/pages/room/state/room-state.service';
import { JanusService } from 'app/common/services/janus.service';
import { Router } from '@angular/router';
import { DetachModeService } from 'app/common/services/detach-mode.service';
import {
  IControlChatMessageBody,
  IFeed,
  VIDEO_CALL_CONTAINER_ID_PREFIX,
} from '@itorum/models';
import { skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IPublisher } from 'app/+conference/pages/room/components/util';
import { BASE_PATH } from '@itorum/api';
import { groupBy } from 'groupby-array';

@Component({
  selector: 'itorum-video-call-container',
  templateUrl: './video-call-container.component.html',
  styleUrls: ['./video-call-container.component.scss'],
})
export class VideoCallContainerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('video_tag') video_tag: ElementRef;
  @ViewChild('chat_input') chatInput: ElementRef;
  @ViewChild('publishers_container')
  publishersContainer: ElementRef<HTMLDivElement>;
  dragPosition = { x: 0, y: -350 };
  members_count = 0;
  messages_count = 0;
  display: 'call' | 'messages' | 'members' = 'call';
  isMenuExpanded = false;
  private destroy$ = new Subject();
  private publishers: IPublisher[] = [];
  /**
   * последний обновлённый список фидов, обновляется часто
   * @private
   */
  private stateFeeds: IFeed[];
  isAttached: boolean;
  dynamicRows = 1;
  empty = [];
  isShowVoiceRecordPanel = false;
  mutedWatch = new Map<number, HTMLVideoElement>();

  get isShowPullers(): boolean {
    if (this.stateFeeds?.length < 2) {
      return false;
    }

    let count = 0;
    this.stateFeeds.forEach((feed) => {
      count += this.isFeedHasVideo(feed) ? 1 : 0;
    });

    if (count > 1) {
      return true;
    } else {
      return false;
    }
  }

  get link(): string {
    return `${this?.basePath}/account/invite/${this.janusService.uid}`;
  }

  constructor(
    public roomStateQuery: RoomStateQuery,
    public roomStateService: RoomStateService,
    public janusService: JanusService,
    public detachModeService: DetachModeService,
    private router: Router,
    @Inject(BASE_PATH) private basePath: string
  ) {}

  ngOnInit(): void {
    this.roomStateQuery
      .getIsVideoAttached()
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((isAttached) => {
        console.log(
          'VideoCallContainerComponent roomStateQuery.getIsVideoAttached =>',
          isAttached
        );
        this.isAttached = isAttached;
        const video: HTMLVideoElement = this.video_tag?.nativeElement;
        console.log('video HTMLVideoElement =>', video);
        if (isAttached) {
          const filtered = this.filterDuplicates(this.publishers);
          this.plugPublishers(filtered);
          if (video) {
            (video as any).muted = '';
          }
          // this.video_tag.nativeElement.play();
        } else {
          if (video) {
            (video as any).muted = 'muted';
          }
          // this.video_tag?.nativeElement?.stop();
          // todo При возвращении в комнату, если на К-в-К идут трансляции видео, их нужно переподключить к тегам в комнате
          this.detachModeService
            .reFeedCurrentPublishers(this.publishersContainer)
            .then(
              (
                tagPairs: Map<
                  number,
                  { to: HTMLVideoElement; from: HTMLVideoElement }
                >
              ) => {
                console.log(
                  'VideoCallContainerComponent reFeedCurrentPublishers tagPairs =>',
                  tagPairs
                );

                tagPairs.forEach(
                  (pair: { to: HTMLVideoElement; from: HTMLVideoElement }) => {
                    this.janusService.reattachMediaStreamFormTo(
                      pair.to,
                      pair.from
                    );
                  }
                );

                this.detachModeService.deactivateDetachMode();
                this.detachModeService.dropInstance();
                this.unPlugPublishers(this.publishers);
              }
            );
        }
      });

    this.roomStateQuery
      .getFeeds()
      .pipe(takeUntil(this.destroy$))
      .subscribe((feeds) => {
        console.log(
          'VideoCallContainerComponent roomStateQuery.getFeeds =>',
          feeds
        );

        // приходит до 3-4 раз повторно (будет содержать последний актуальный список фидов)
        // можно не синхронизировать его и не ориентироваться на него
        this.stateFeeds = feeds;
      });

    this.janusService.publishers$ // -== GET PUBLISHERS IN ROOM ==-
      .pipe(takeUntil(this.destroy$))
      .subscribe((publishers) => {
        if (!publishers) {
          console.log(
            'VideoCallContainerComponent new_publisher_connected$ no publishers first time'
          );
          return;
        }
        console.log(
          'VideoCallContainerComponent init publishers first time =>',
          publishers
        );
        // if (this.isAttached) {
        this.publishers = publishers;
        // }
      });

    this.janusService.new_publisher_connected$ // -== NEW PUBLISHER CONNECTED ==-
      .pipe(takeUntil(this.destroy$))
      .subscribe((publishers: IPublisher[]) => {
        console.log(
          'VideoCallContainerComponent new_publisher_connected$ =>',
          publishers
        );
        if (!publishers) {
          console.log(
            'VideoCallContainerComponent new_publisher_connected$ no publishers'
          );
          return;
        }

        const filtered = this.filterDuplicates(publishers);
        console.log(
          'VideoCallContainerComponent new_publisher_connected$ after add filtered =>',
          filtered
        );

        this.addByFilteringPublishers(filtered);

        if (this.isAttached) {
          //remove duplicate tags before plug
          this.unPlugPublishers(filtered);
          // add fresh tags
          this.plugPublishers(filtered);
        }

        console.log(
          'VideoCallContainerComponent new_publisher_connected$ after add publishers =>',
          publishers
        );
      });

    this.janusService.publisher_unpublished$
      .pipe(takeUntil(this.destroy$))
      .subscribe((publisher) => {
        console.log(
          'VideoCallContainerComponent publisher_unpublished$ ',
          publisher
        );
        if (!publisher) {
          return;
        }

        this.clear(publisher?.id); // если пришёл сигнал о выходе клиента - убрать его тэг, publisher и feed
      });

    this.janusService.someVideoStateChanges$ // удалённый фид начал/прекратил трансляцию видео
      .pipe(takeUntil(this.destroy$))
      .subscribe((feed: IFeed) => {
        console.log(
          'VideoCallContainerComponent someVideoStateChanges$ feed =>',
          feed
        );

        if (feed.video.hasVideo) {
          this.janusService.videoAttach(feed); //some duplicates
        }
      });
  }

  ngAfterViewInit(): void {
    this.janusService.saveAttach(this.video_tag);
    const video: HTMLVideoElement = this.video_tag.nativeElement;

    if (video) {
      (video as any).muted = 'muted';
    } else {
      console.error('no video tag present');
    }

    this.janusService.muted.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (this.isAttached) {
        if (video) {
          val ? ((video as any).muted = 'muted') : ((video as any).muted = '');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  returnToRoom() {
    this.roomStateService.doVideoAttach(false);
    this.router
      .navigateByUrl(`main/conference/room/${this.janusService.currentRoom}`)
      .then((value) => {
        console.log('returnToRoom =>', value);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  trackByChatId(index: number, control: IControlChatMessageBody) {
    return control.timestamp;
  }

  openImage(url) {
    window.open(url, '_blank');
  }

  private addNewVideoTag(id: number): HTMLVideoElement {
    const videoTag: HTMLVideoElement = document.createElement('video');
    videoTag.style.cssText = 'height: 0';
    videoTag.classList.add('att-publisher');
    videoTag.id = `${VIDEO_CALL_CONTAINER_ID_PREFIX}${id}`;
    videoTag.setAttribute('autoplay', '');
    videoTag.setAttribute('playsinline', '');
    return this.publishersContainer.nativeElement.insertAdjacentElement(
      'afterbegin',
      videoTag
    ) as HTMLVideoElement;
  }

  private removeVideoTag(id: number) {
    if (!id) {
      console.error('removeVideoTag id =>', id);
      return;
    }
    const attr_id = `${VIDEO_CALL_CONTAINER_ID_PREFIX}${id}`;
    console.log('removeVideoTag attr_id =>', attr_id);
    const tag = document.getElementById(attr_id);
    console.log('removeVideoTag tag =>', tag);

    if (tag) {
      tag?.parentNode?.removeChild(tag);
    } else {
      console.warn('no tag for removing by this id =>', id);
    }
  }

  clear(id?: number) {
    console.log('VideoCallContainerComponent id =>', id);
    if (id) {
      const index = this.publishers.findIndex((p) => p.id === id);
      // const findex = this.stateFeeds.findIndex((p) => p.rfid === id);
      console.log('VideoCallContainerComponent index =>', index);

      if (index !== -1) {
        this.removeVideoTag(id);
        delete this.publishers[index];
      }

      // if (findex !== -1) {
      //   delete this.stateFeeds[findex];
      // }
    } else {
      console.error('no id passed for clear');
    }
  }

  private plugPublishers(publishers?: IPublisher[]) {
    console.log('VideoCallContainerComponent plugPublishers =>', publishers);

    publishers?.forEach((publisher) => {
      console.log('VideoCallContainerComponent id => ', publisher?.id);
      const tag = this.addNewVideoTag(publisher?.id); //todo add check for by id repeating

      // const tag = this.videoTags[i].nativeElement;
      this.janusService.newremoteFeed(
        publisher.id,
        publisher.display,
        publisher.audio_codec,
        publisher.video_codec,
        tag
      );
    });
  }

  private reattachPublishers(publishers?: IPublisher[]) {
    publishers?.forEach((publisher) => {
      console.log(
        'VideoCallContainerComponent try Janus reattach id => ',
        publisher?.id
      );
    });
  }

  private clearPublishersAndFeeds() {
    console.log('VideoCallContainerComponent clearPublishers invoked');
    console.log('publishers before clearing =>', this.publishers);
    this.unPlugPublishers(this.publishers);
    this.publishers = [];
    this.stateFeeds = [];
  }

  private unPlugPublishers(publishers?: IPublisher[]) {
    publishers?.forEach((publisher) => {
      this.removeVideoTag(publisher.id);
    });
  }

  janusAttachMediaToTag(tag: HTMLVideoElement, feed) {
    this.janusService.attachMediaStreamToTag(feed, tag);
  }

  checkRows() {
    let len = this.chatInput?.nativeElement?.value?.length || 30;
    len = len + 15;
    this.dynamicRows = Math.ceil(len / 30);
  }

  sendChatMessage(text: string) {
    this.detachModeService?.getInstance()?.sendChatMessage(text);
  }

  startVoiceRecord() {
    this.detachModeService?.getInstance()?.startVoiceRecord();
  }

  cancelVoiceRecord() {
    this.detachModeService?.getInstance()?.cancelVoiceRecord();
  }

  submitVoiceRecord() {
    this.detachModeService?.getInstance()?.submitVoiceRecord();
  }

  clipRead() {
    navigator.clipboard.writeText(this.link);
  }

  isFeedHasVideo(feed: IFeed): boolean {
    return !!feed?.video?.hasVideo;
  }

  nextVideo() {
    const videoFeed = this.getVideoFeed();
    const nowVideoFeeds = this.getNowVideoFeeds();

    let nowIndex = this.getNowIndex(nowVideoFeeds, videoFeed);

    if (nowIndex === 0) {
      nowIndex = nowVideoFeeds.length - 1;
    } else {
      nowIndex--;
    }

    this.janusService.videoAttach(nowVideoFeeds[nowIndex]);
  }

  prevVideo() {
    const videoFeed = this.getVideoFeed();
    const nowVideoFeeds = this.getNowVideoFeeds();
    let nowIndex = this.getNowIndex(nowVideoFeeds, videoFeed);

    if (nowIndex === nowVideoFeeds.length - 1) {
      nowIndex = 0;
    } else {
      nowIndex++;
    }

    this.janusService.videoAttach(nowVideoFeeds[nowIndex]);
  }

  private getNowVideoFeeds(): IFeed[] {
    return this.stateFeeds.filter((feed) => this.isFeedHasVideo(feed));
  }

  private getVideoFeed() {
    let videoFeed = this.janusService._nowInAttachSaveFeed;
    if (!videoFeed) {
      videoFeed = this.janusService._nowCenteredFeed;
    }
    return videoFeed;
  }

  private getNowIndex(nowVideoFeeds, videoFeed) {
    return nowVideoFeeds.findIndex((feed) => feed.rfid === videoFeed.rfid);
  }

  endCall() {
    this.roomStateService.doVideoAttach(false);
    this.detachModeService.deactivateDetachMode();
    this.detachModeService.getInstance().ngOnDestroy();
    this.detachModeService.dropInstance();
  }

  private addByFilteringPublishers(publishers: IPublisher[]) {
    publishers.forEach((publisher) => {
      // remove duplicate before adding
      const cloneIdx = this.publishers.findIndex(
        (clone) => clone?.id === publisher.id
      );

      if (cloneIdx !== -1) {
        delete this.publishers[cloneIdx];
      }

      // add fresh publisher
      this.publishers.push(publisher);
    });
  }

  private filterDuplicates(publishers: IPublisher[]) {
    const groupsById = groupBy(publishers, (p) => p.id);
    const result = [];
    for (const key in groupsById) {
      console.log('filterDuplicates groupsById[key] =>', groupsById[key]);
      result.push(groupsById[key][groupsById[key].length - 1]);
    }

    return result;
  }

  toggleMute(feed) {
    console.log('toggleMute feed =>', feed);
    const id = feed.rfid;
    const attr_id = `${VIDEO_CALL_CONTAINER_ID_PREFIX}${id}`;
    console.log('toggleMute attr_id =>', attr_id);
    const tag: HTMLVideoElement = document.getElementById(
      attr_id
    ) as HTMLVideoElement;
    console.log('toggleMute tag =>', tag);

    // (tag as any).muted === 'muted' ? (tag as any).muted = '' : (tag as any).muted = 'muted';
    // tag.muted ? (tag as any).muted = false : (tag as any).muted = true;
    tag.muted = !tag.muted;
    console.log('toggleMute (tag as any).muted', (tag as any).muted);
    this.mutedWatch.set(id, tag);
    console.log('toggleMute this.mutedWatch', this.mutedWatch);
  }
}
