/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import {
  EmailCheckBody,
  UserGuestBody,
  GuestCreationResponse,
  EmailCheckResponse,
  RoomUidCheckResponse,
} from '@itorum/api';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class RemoteExpert2Service {
  protected basePath = 'https://dev.itorummr.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check token
   *
   * @param roomUid Room uuid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2RoomRoomUidCheckGet(
    roomUid: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RoomUidCheckResponse>;
  public apiRemoteExpert2RoomRoomUidCheckGet(
    roomUid: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RoomUidCheckResponse>>;
  public apiRemoteExpert2RoomRoomUidCheckGet(
    roomUid: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RoomUidCheckResponse>>;
  public apiRemoteExpert2RoomRoomUidCheckGet(
    roomUid: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (roomUid === null || roomUid === undefined) {
      throw new Error(
        'Required parameter roomUid was null or undefined when calling apiRemoteExpert2RoomRoomUidCheckGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<{ status?: boolean }>(
      'get',
      `${this.basePath}/api/remote-expert2/room/${encodeURIComponent(
        String(roomUid)
      )}/check`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * user email check
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2UserEmailCheckPost(
    body?: EmailCheckBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmailCheckResponse>;
  public apiRemoteExpert2UserEmailCheckPost(
    body?: EmailCheckBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmailCheckResponse>>;
  public apiRemoteExpert2UserEmailCheckPost(
    body?: EmailCheckBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmailCheckResponse>>;
  public apiRemoteExpert2UserEmailCheckPost(
    body?: EmailCheckBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<EmailCheckResponse>(
      'post',
      `${this.basePath}/api/remote-expert2/user/email/check`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * user guest create
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2UserGuestPost(
    body?: UserGuestBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GuestCreationResponse>;
  public apiRemoteExpert2UserGuestPost(
    body?: UserGuestBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GuestCreationResponse>>;
  public apiRemoteExpert2UserGuestPost(
    body?: UserGuestBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GuestCreationResponse>>;
  public apiRemoteExpert2UserGuestPost(
    body?: UserGuestBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GuestCreationResponse>(
      'post',
      `${this.basePath}/api/remote-expert2/user/guest`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
