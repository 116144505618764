<div
  class="vonage-studio-label"
  (mouseenter)="model.setHovered(); onHover()"
  (mouseleave)="model.setHovered(false)"
  (click)="deleteLink()"
  [ngStyle]="{
    transform:
      'translate(' +
      ((model.selectCoords() | async).x - 35) +
      'px, ' +
      ((model.selectCoords() | async).y - 35) +
      'px)'
  }"
  [ngClass]="{
    show:
      (model.getParent().selectHovered() | async) ||
      (model.selectHovered() | async)
  }"
>
  <i class="icon-basket font-black"></i>
</div>
