<div class="my-toast-dialog flex items-center">
  <div class="toast-header" *ngIf="title" [innerHTML]="title"></div>
  <div class="toast-body flex justify-between px-4" [innerHTML]="text"></div>
  <div class="toast-body flex">
    <button class="button button--red mr-2" (click)="cancel()">
      <!--      <i class="icon-cross mr-1"></i>-->
      <!--      Отказать-->
      {{ 'common.toastr-invite-dialog.reject' | translate }}
    </button>
    <button class="button button--green" (click)="okay()">
      <!--      <i class="icon-ck mr-1"></i>-->
      <!--      Принять-->
      {{ 'common.toastr-invite-dialog.accept' | translate }}
    </button>
  </div>
</div>
