/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { InlineResponse2002 } from '../model/inlineResponse2002';
import { InlineResponse2003 } from '../model/inlineResponse2003';
import { InlineResponse2004 } from '../model/inlineResponse2004';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class EntityStatusesService {
  protected basePath = 'https://dev.itorum-mr.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get all entity statuses by jobTypeId
   *
   * @param jobTypeId Job type id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiEntityStatusGetAllByJobTypeIdGet(
    jobTypeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2002>;
  public apiEntityStatusGetAllByJobTypeIdGet(
    jobTypeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2002>>;
  public apiEntityStatusGetAllByJobTypeIdGet(
    jobTypeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2002>>;
  public apiEntityStatusGetAllByJobTypeIdGet(
    jobTypeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobTypeId === null || jobTypeId === undefined) {
      throw new Error(
        'Required parameter jobTypeId was null or undefined when calling apiEntityStatusGetAllByJobTypeIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (jobTypeId !== undefined && jobTypeId !== null) {
      queryParameters = queryParameters.set('jobTypeId', <any>jobTypeId);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2002>(
      'get',
      `${this.basePath}/api/entity-status/get-all-by-job-type-id`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all entity statuses
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiEntityStatusGetAllStatusesGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2002>;
  public apiEntityStatusGetAllStatusesGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2002>>;
  public apiEntityStatusGetAllStatusesGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2002>>;
  public apiEntityStatusGetAllStatusesGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2002>(
      'get',
      `${this.basePath}/api/entity-status/get-all-statuses`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all entity statuses
   *
   * @param jobId Job id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiEntityStatusJobIdStatusHistoryGet(
    jobId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2004>;
  public apiEntityStatusJobIdStatusHistoryGet(
    jobId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2004>>;
  public apiEntityStatusJobIdStatusHistoryGet(
    jobId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2004>>;
  public apiEntityStatusJobIdStatusHistoryGet(
    jobId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobId === null || jobId === undefined) {
      throw new Error(
        'Required parameter jobId was null or undefined when calling apiEntityStatusJobIdStatusHistoryGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2004>(
      'get',
      `${this.basePath}/api/entity-status/${encodeURIComponent(
        String(jobId)
      )}/status-history`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all entity status transitions by statusId
   *
   * @param statusId Entity status id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiEntityStatusStatusIdGetTransitionsGet(
    statusId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2003>;
  public apiEntityStatusStatusIdGetTransitionsGet(
    statusId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2003>>;
  public apiEntityStatusStatusIdGetTransitionsGet(
    statusId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2003>>;
  public apiEntityStatusStatusIdGetTransitionsGet(
    statusId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (statusId === null || statusId === undefined) {
      throw new Error(
        'Required parameter statusId was null or undefined when calling apiEntityStatusStatusIdGetTransitionsGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2003>(
      'get',
      `${this.basePath}/api/entity-status/${encodeURIComponent(
        String(statusId)
      )}/get-transitions`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
