import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionService } from '../services/permission.service';
import { UserService } from '../services/user.service';

@Injectable()
export class UniversalGuard implements CanActivate {
  constructor(
    private permission: PermissionService,
    private user: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.user.client$.pipe(
      map((user) => {
        if (this.permission.checkForPermission(route.url[0].path)) {
          return true;
        } else {
          if (!this.permission.navigateHome()) {
            this.user.logout();
          }
          return false;
        }
      })
    );
  }
}
