/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ApiObjectsBody } from '../model/apiObjectsBody';
import { ObjectResponse } from '../model/objectResponse';
import { ObjectFindResponse } from '../model/objectFindResponse';
import { ObjectsFindBody } from '../model/objectsFindBody';
import { ObjectsObjectIdBody } from '../model/objectsObjectIdBody';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ObjectsService {
  protected basePath = 'https://dev.itorummr.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Find action templates
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiObjectsFindPost(
    body?: ObjectsFindBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ObjectFindResponse>;
  public apiObjectsFindPost(
    body?: ObjectsFindBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ObjectFindResponse>>;
  public apiObjectsFindPost(
    body?: ObjectsFindBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ObjectFindResponse>>;
  public apiObjectsFindPost(
    body?: ObjectsFindBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ObjectFindResponse>(
      'post',
      `${this.basePath}/api/objects/find`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get template import file
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiObjectsImportTemplateGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public apiObjectsImportTemplateGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public apiObjectsImportTemplateGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public apiObjectsImportTemplateGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/octet-stream'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      headers = headers.set(
        'Content-Disposition',
        'attachment;filename=import-objects-template.xlsx'
      );
      // headers = headers.set('Content-Type', 'application/vnd.ms-excel');
      // .setContentType("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8")
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/api/objects/import/template`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      responseType: 'blob',
    });
  }

  /**
   * Import objects from file
   *
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiObjectsImportUploadPostForm(
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<{ data?: Array<any>; status?: boolean }>;
  public apiObjectsImportUploadPostForm(
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<{ data?: Array<any>; status?: boolean }>>;
  public apiObjectsImportUploadPostForm(
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<{ data?: Array<any>; status?: boolean }>>;
  public apiObjectsImportUploadPostForm(
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    const convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec(),
      });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<{ data?: Array<any>; status?: boolean }>(
      'post',
      `${this.basePath}/api/objects/import/upload`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get object by id
   *
   * @param objectId Object id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiObjectsObjectIdGet(
    objectId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ObjectResponse>;
  public apiObjectsObjectIdGet(
    objectId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ObjectResponse>>;
  public apiObjectsObjectIdGet(
    objectId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ObjectResponse>>;
  public apiObjectsObjectIdGet(
    objectId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (objectId === null || objectId === undefined) {
      throw new Error(
        'Required parameter objectId was null or undefined when calling apiObjectsObjectIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ObjectResponse>(
      'get',
      `${this.basePath}/api/objects/${encodeURIComponent(String(objectId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update object
   *
   * @param objectId Object id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiObjectsObjectIdPut(
    objectId: number,
    body?: ObjectsObjectIdBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ObjectResponse>;
  public apiObjectsObjectIdPut(
    objectId: number,
    body?: ObjectsObjectIdBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ObjectResponse>>;
  public apiObjectsObjectIdPut(
    objectId: number,
    body?: ObjectsObjectIdBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ObjectResponse>>;
  public apiObjectsObjectIdPut(
    objectId: number,
    body?: ObjectsObjectIdBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (objectId === null || objectId === undefined) {
      throw new Error(
        'Required parameter objectId was null or undefined when calling apiObjectsObjectIdPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ObjectResponse>(
      'put',
      `${this.basePath}/api/objects/${encodeURIComponent(String(objectId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create object
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiObjectsPost(
    body?: ApiObjectsBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ObjectResponse>;
  public apiObjectsPost(
    body?: ApiObjectsBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ObjectResponse>>;
  public apiObjectsPost(
    body?: ApiObjectsBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ObjectResponse>>;
  public apiObjectsPost(
    body?: ApiObjectsBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ObjectResponse>(
      'post',
      `${this.basePath}/api/objects`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
