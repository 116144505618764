import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { StorageService } from '@itorum/services';
import { map } from 'rxjs/operators';

@Injectable()
export class ImageService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  pdfToBitmap(file) {
    const Authorization = this.storage.getAuthToken();

    const fileName = btoa(encodeURIComponent(file.name));

    const request = new HttpRequest('POST', '/api/images/pdftoimage', file, {
      headers: new HttpHeaders({
        Authorization,
        'x-file-name': fileName,
        'Content-Type': 'application/pdf',
      }),
    });

    return this.http
      .request(request)
      .pipe(map((response: any) => response.body))
      .toPromise();
  }

  fetchImageAsBitmap(file) {
    return this.http
      .get('/api/images/pdfimage/' + file.name, { responseType: 'blob' })
      .pipe(map((blob: any) => createImageBitmap(blob)))
      .toPromise();
  }
}
