import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ICallCandidate } from '@itorum/models';

export interface CommonStateState {
  callCandidates: ICallCandidate[];
}

export function createInitialState(): CommonStateState {
  return {
    callCandidates: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'CommonState' })
export class CommonStateStore extends Store<CommonStateState> {
  constructor() {
    super(createInitialState());
  }
}
