import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ITagValue } from '@itorum/models';

export interface WsState {
  wsData: ITagValue[];
}

export const getInitialState = (): WsState => {
  return {
    wsData: [],
  };
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'device-monitor-module' })
export class WsStore extends Store<WsState> {
  constructor() {
    super(getInitialState());
  }
}
