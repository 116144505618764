<div class="my-toast-dialog flex items-center">
  <div class="toast-header pr-2" *ngIf="title" [innerHTML]="title"></div>
  <div class="toast-body pr-2" [innerHTML]="text"></div>
  <div class="toast-body" style="text-align: center">
    <button class="button button--red mr-2" (click)="cancel()">
      <!--      <i class="icon-cross mr-1"></i>-->
      {{ 'common.cancel' | translate }}
    </button>
  </div>
</div>
