import { formatTimeZoneDate } from '../utils';

export class UserSession {
  session_id: number;
  fio: string;
  ip: string;
  mac: string;
  start_time: string;
  start_time_formated: string;
  end_time: string;
  end_time_formated: string;
  status_name: string;
  model_name: string;

  constructor(obj) {
    if (obj['session_id'] && typeof obj['session_id'] === 'number') {
      this.session_id = obj['session_id'];
    }
    if (obj['fio'] && typeof obj['fio'] === 'string') {
      this.fio = obj['fio'];
    }
    if (obj['ip'] && typeof obj['ip'] === 'string') {
      this.ip = obj['ip'];
    }
    if (obj['mac'] && typeof obj['mac'] === 'string') {
      this.mac = obj['mac'];
    }
    if (obj['start_time'] && typeof obj['start_time'] === 'string') {
      this.start_time = obj['start_time'];
      this.start_time_formated = formatTimeZoneDate(
        this.start_time,
        'Europe/Moscow',
        'DD.MM.YYYY H:mm:ss'
      );
    }
    if (obj['end_time'] && typeof obj['end_time'] === 'string') {
      this.end_time = obj['end_time'];
      this.end_time_formated = formatTimeZoneDate(
        this.end_time,
        'Europe/Moscow',
        'DD.MM.YYYY H:mm:ss'
      );
    }
    if (obj['status'] && typeof obj['status'] === 'string') {
      this.status_name = obj['status'];
    }
    if (obj['model_name'] && typeof obj['model_name'] === 'string') {
      this.model_name = obj['model_name'];
    } else {
      this.model_name = 'PC';
    }
  }
}
