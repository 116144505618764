import { User } from './user.model';

class GlassesInitializer {
  id: number;
  glass_id: number;
  mac: string;
  for_expert: boolean;
  for_inspector: boolean;
  for_tk: boolean;
  model_name: string;
  serial_num: string;
  status_name: string;
  techniks: Array<User>;

  constructor(obj) {
    if (obj['glass_id'] && typeof obj['glass_id'] === 'number') {
      this.glass_id = obj['glass_id'];
    }
    if (obj['glass_id'] && typeof obj['glass_id'] === 'number') {
      this.id = obj['glass_id'];
    }
    if (obj['mac'] && typeof obj['mac'] === 'string') {
      this.mac = obj['mac'];
    } else {
      this.mac = '';
    }
    if (obj['for_expert'] && typeof obj['for_expert'] === 'boolean') {
      this.for_expert = obj['for_expert'];
    } else {
      this.for_expert = false;
    }
    if (obj['for_inspector'] && typeof obj['for_inspector'] === 'boolean') {
      this.for_inspector = obj['for_inspector'];
    } else {
      this.for_inspector = false;
    }
    if (obj['for_tk'] && typeof obj['for_tk'] === 'boolean') {
      this.for_tk = obj['for_tk'];
    } else {
      this.for_tk = false;
    }
    if (obj['model_name'] && typeof obj['model_name'] === 'string') {
      this.model_name = obj['model_name'];
    }
    if (obj['serial_num'] && typeof obj['serial_num'] === 'string') {
      this.serial_num = obj['serial_num'];
    }
    if (obj['status_name'] && typeof obj['status_name'] === 'string') {
      this.status_name = obj['status_name'];
    }
    this.techniks = new Array<User>();
    if (obj['techniks'] && typeof obj['techniks'] === 'object') {
      this.techniks = obj['techniks'].map((item) => {
        return new User(item);
      });
    }
  }
}

export class Glasses extends GlassesInitializer {
  constructor(user) {
    super(user);
  }
}
