import { of as observableOf, Observable, throwError } from 'rxjs';
import { tap, catchError, filter, map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { IApiResponse } from '@itorum/models';

@Injectable()
export class ApiService {
  public unauthorized = new EventEmitter<void>();

  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  public get(
    url: string,
    options: any = {},
    extraOptions: any = {}
  ): Observable<IApiResponse> {
    return this.http
      .get<IApiResponse>(url, Object.assign(options, { observe: 'response' }))
      .pipe(
        catchError((response: HttpErrorResponse) => this.catchError(response)),
        filter((response: HttpResponse<IApiResponse>) =>
          this.checkResponse(response.body, response.url, extraOptions)
        ),
        map((response: HttpResponse<IApiResponse>) => response.body)
      );
  }

  public downloadFile(url: string, body?: any): Observable<any> {
    return (
      body
        ? this.http.post(url, body, {
            responseType: 'blob',
            observe: 'response',
          })
        : this.http.get(url, { responseType: 'blob', observe: 'response' })
    ).pipe(
      tap((data: HttpResponse<Blob>) => {
        let fileName = data.headers.get('Content-Disposition');
        fileName = fileName
          ? fileName.split(';')[1].split('=')[1].replace(/"/g, '')
          : fileName;
        const blob = new Blob([data.body], {
          type: data.headers.get('Content-Type'),
        });
        if (blob.size > 0) {
          saveAs(blob, fileName, true);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Blob) {
          const reader = new FileReader();
          reader.addEventListener('loadend', (e: ProgressEvent) => {
            this.checkResponse(JSON.parse(e.srcElement['result']), url);
          });
          reader.readAsText(error.error);
        }
        // console.log('Error downloading the file.', error);
        return observableOf(error);
      })
    );
    // .finally(() => console.info('downloadFile: OK'))
  }

  /**
   * Метод для отправки patch запроса на сервер
   * @param url
   * @param body
   * @param options
   */
  public patch(
    url: string,
    body?: any,
    options?: any
  ): Observable<IApiResponse> {
    return this.http
      .patch<IApiResponse>(
        url,
        body,
        Object.assign(options || {}, { observe: 'response' })
      )
      .pipe(
        catchError((response: HttpErrorResponse) => this.catchError(response)),
        map((response: HttpResponse<IApiResponse>) => {
          this.checkResponse(response.body, response.url);
          return response.body;
        })
      );
  }

  public post(
    url: string,
    body: any | null,
    options: any = {}
  ): Observable<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        url,
        body,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((response: HttpErrorResponse) => this.catchError(response)),
        map((response: HttpResponse<IApiResponse>) => {
          this.checkResponse(response.body, response.url);
          return response.body;
        })
      );
  }

  public postForSwal(url: string, body: any | null, options: any = {}) {
    return this.http
      .post<IApiResponse>(
        url,
        body,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((response: HttpErrorResponse) => this.catchError(response)),
        map((response: HttpResponse<IApiResponse>) => {
          return response.body;
        })
      );
  }

  public delete(url: string, options: any = {}): Observable<IApiResponse> {
    return this.http
      .delete<IApiResponse>(
        url,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((response: HttpErrorResponse) => this.catchError(response)),
        filter((response: HttpResponse<IApiResponse>) =>
          this.checkResponse(response.body, response.url)
        ),
        map((response: HttpResponse<IApiResponse>) => response.body)
      );
  }

  public put(
    url: string,
    body: any | null,
    options: any = {}
  ): Observable<IApiResponse> {
    return this.http
      .put<IApiResponse>(
        url,
        body,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((response: HttpErrorResponse) => this.catchError(response)),
        filter((response: HttpResponse<IApiResponse>) =>
          this.checkResponse(response.body, response.url)
        ),
        map((response: HttpResponse<IApiResponse>) => response.body)
      );
  }

  private catchError(response: HttpErrorResponse) {
    if (response.status === 401) {
      this.unauthorized.emit();
    } else {
      this.checkResponse(response.error, response.url);
    }
    return throwError(response);
  }

  private checkResponse(
    apiResponse: IApiResponse,
    url: string,
    options: any = {}
  ): boolean {
    if (apiResponse.status || apiResponse instanceof Blob) {
      if (apiResponse.description) {
        this.toastrService.success(apiResponse.description);
      }
      return true;
    }

    if (Array.isArray(apiResponse.debugInfo)) {
      apiResponse.debugInfo.forEach((msg) => console.log(url, msg));
    }

    const errors =
      apiResponse?.errors ||
      apiResponse?.description ||
      apiResponse?.error?.message ||
      apiResponse?.error;

    if (errors) {
      let errs = '';
      []
        .concat(errors)
        .forEach(
          (err) =>
            (errs +=
              (typeof err === 'object' ? Object.values(err) : err) + '\n')
        );
      // this.toastrService.error(errs);
      if (options.responseAnyWay) {
        return true;
      }
    } else {
      this.toastrService.error(
        'Ошибка сервера. Подробности в консли браузера (F12)'
      );
    }

    return false;
  }
}
