import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getExtension',
})
export class GetExtensionPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    console.log('GetExtensionPipe value =>', value);
    const [_, ext] = value?.split('.');
    return ext;
  }
}
