import { filter, map, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { PermissionService, ApiService } from 'app/shared';
import { PermissionRolesService } from 'app/common/services/permission-roles.service';
import { StorageService } from '@itorum/services';
import { User, IFullUserInfo, IUserRole, IApiResponse } from '@itorum/models';
import userflow from 'userflow.js';

interface IGetFullUserInfoResponse extends IApiResponse {
  result: IFullUserInfo;
}

@Injectable()
// { providedIn: 'root'}
export class UserService {
  private currentUser: User = null;
  private _logout$ = new Subject<void>();
  private _login$ = new Subject<void>();
  private _client$ = new ReplaySubject<User>(1);

  get logout$() {
    return this._logout$.asObservable();
  }

  get login$() {
    return this._login$.asObservable();
  }

  get client$(): Observable<User> {
    return this._client$.asObservable();
  }

  get flatClient(): User {
    return this.currentUser;
  }

  get isGuest(): boolean {
    return !this.storageService.getAuthInfo().authToken;
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private permissionService: PermissionService,
    private permissionRolesService: PermissionRolesService,
    private toastrService: ToastrService,
    private storageService: StorageService
  ) {
    this.apiService.unauthorized.pipe(debounceTime(500)).subscribe(() => {
      this.logout();
    });

    this.client$.subscribe((client) => {
      if (client.auth_user_id) {
        this._login$.next();
      }
    });
  }

  public can(roleId: number | number[]): boolean {
    // this.permissionRolesService.PermissionRoles
    const roles = this.currentUser?.roles;
    for (const rId of [].concat(roleId)) {
      if (roles?.find((role: IUserRole) => role.role_id === rId)) {
        return true;
      }
    }
    return false;
  }

  public onceCan(roleId: number | number[]) {
    const roles = this.currentUser?.roles;
    return roles.length === 1 && this.can(roleId);
  }

  public isCurrentUser(userId: string | number) {
    return this.currentUser.user_id === userId;
  }

  public login(data: any): Observable<any> {
    return this.apiService.post('/api/user/login', data).pipe(
      map((res: IApiResponse) => {
        if (res.status === true) {
          if (res['token_type'] && res['access_token']) {
            this.storageService.setAuthInfo({
              authType: res['token_type'],
              authToken: res['access_token'],
            });
          }
          return res;
        } else {
          throw new Error(res.error.message || res.error);
        }
      })
    );
  }

  public recoveryPassword(data: any): Observable<any> {
    return this.apiService
      .post('/api/user/reset-password-code/send', data, {
        responseAnyWay: true,
      })
      .pipe(
        map((res: IApiResponse) => {
          if (res.status === true) {
            return res;
          } else {
            throw new Error(res['error']);
          }
        })
      );
  }

  public readResetCode(params: any): Observable<any> {
    return this.apiService
      .get(
        '/api/user/reset-password-code/info',
        { params },
        { responseAnyWay: true }
      )
      .pipe(
        map((res: IApiResponse) => {
          return res;
        })
      );
  }

  public resetPassword(data: any): Observable<any> {
    return this.apiService
      .post('/api/user/reset-password/update', data, { responseAnyWay: true })
      .pipe(
        map((res: IApiResponse) => {
          return res;
        })
      );
  }

  public getFullUserInfo(): Observable<void> {
    return this.apiService
      .post('/api/user/manage', { apitype: 'getfulluserinfo' })
      .pipe(
        filter((response: IGetFullUserInfoResponse) => response.status),
        map((response: IGetFullUserInfoResponse) => {
          const user = new User(response.result);
          this.currentUser = user;
          const userIden = String(response.result.user_id);
          const userLogin = response.result.login;
          const userEmail = response.result.email;
          const userCreateAt = String(response.result.status_start_date);
          const fullname = String(
            response.result.first_name +
              ' ' +
              response.result.last_name +
              ' ' +
              response.result.middle_name
          );
          const hostname = window.location.hostname;
          userflow.init('ct_co45om2ocbawtfj23qdpk25zme');
          userflow.identify(userIden, {
            name: userLogin,
            email: userEmail,
            signed_up_at: userCreateAt,
            fullname: fullname,
            hostname: hostname,
          });
          this.permissionService.roles = user.roles;
          return this._client$.next(user);
        })
      );
  }

  public logout(): void {
    if (this.currentUser?.auth_user_id) {
      this.apiService.post('/api/user/logout', {}).subscribe(() => {
        console.log('Пользователь завершил сеанс');
      });
    }
    this._logout$.next();
    this.storageService.clearAuth();
    this._client$.next(new User({}));
    this.router.navigateByUrl('/account').catch((err) => console.log(err));
  }
}
