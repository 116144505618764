import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrinterService } from './printer.service';

@NgModule({
  imports: [CommonModule],
  providers: [PrinterService, { provide: 'window', useValue: window }],
  declarations: [],
})
export class SharedServicesModule {}
