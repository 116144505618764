export interface IEmployee {
  firstName: string; // "Роман"
  fullName: string; // "Киселёв Роман"
  id: number; // 6
  lastName: string; // "Киселёв"
  middleName: string; // ""
  username: string; // "mariyapozdyak"
}

export interface IRecord {
  audio: string; // "http://webrtc-dev.itorum-mr.ru/output/room/515/audio-6b6c95040df24ed382dd49e887267a5f.opus"
  status: string; // "finished"
  video: string; // "http://webrtc-dev.itorum-mr.ru/output/room/515/video-6b6c95040df24ed382dd49e887267a5f.webm"
  users?: { id: number; video?: string; audio?: string; status: string }[];
}

export interface IRoomRecord {
  created_at: string; // "2021-06-30T08:04:31.586Z"
  description: string;
  id: number; // 515
  initiator: IEmployee;
  media: { images: any[] };
  messages: IMessage[];
  meta: any;
  organization: { id: number; name: string };
  participants: IEmployee[];
  records: IRecord;
  title: string; // "Киселёв Роман"
  uid: string; // "c111129cb8b"
}

export interface IMessage {
  id: number;
  timestamp: string; // '2022-07-07T06:13:57.058Z'
  type: 'media' | 'text';
  user: number;
  data: { text: string; inputType: 'voice' | 'text' } | { url: string };
}

export interface IConferenceRecord {
  room: IRoomRecord;
}

export class RoomRecord implements IRoomRecord {
  created_at: string;
  description: string;
  id: number;
  initiator: IEmployee;
  media: { images: any[] };
  messages: IMessage[];
  meta: any;
  organization: { id: number; name: string };
  participants: IEmployee[];
  records: IRecord;
  title: string;
  uid: string;

  constructor(data?: Partial<IRoomRecord>) {
    this.created_at = data?.created_at;
    this.description = data?.description;
    this.id = data?.id;
    this.initiator = data?.initiator;
    this.media = data?.media;
    this.messages = data?.messages;
    this.meta = data?.meta;
    this.organization = data?.organization;
    this.participants = data?.participants;
    this.records = data?.records;
    this.title = data?.title;
    this.uid = data?.uid;
  }
}
