<div class="ag-filter" style="padding: 4px">
  <div>
    <div class="ag-filter-body-wrapper">
      <div ref="ag-filter-loading" class="loading-filter ag-hidden">
        Loading...
      </div>
      <div>
        <div class="ag-filter-header-container" (click)="toggleEverything()">
          <label id="selectAllContainer">
            <!--            <div id="selectAll" class="ag-filter-checkbox">-->
            <!--              <span-->
            <!--                class="ag-icon"-->
            <!--                [ngClass]="-->
            <!--                  isEverythingSelected-->
            <!--                    ? 'ag-icon-checkbox-checked'-->
            <!--                    : 'ag-icon-checkbox-unchecked'-->
            <!--                "-->
            <!--              ></span>-->
            <!--            </div>-->
            <div
              id="selectAll"
              class="mr-1 ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
              [ngClass]="isEverythingSelected ? 'ag-checked' : ''"
            ></div>
            <span class="ag-filter-value">(Выбрать все)</span>
          </label>
        </div>
        <div id="richList" class="ag-set-filter-list">
          <div class="ag-virtual-list-viewport">
            <div
              class="ag-virtual-list-container"
              [attr.style.height]="containerHeight"
            >
              <div
                style="line-height: 28px"
                *ngFor="let item of onFilterValuesChanged$ | async"
                (click)="toggleItem(item)"
              >
                <label class="ag-set-filter-item">
                  <!--                  <div class="ag-filter-checkbox">-->
                  <!--                    <span-->
                  <!--                      class="ag-icon"-->
                  <!--                      [ngClass]="-->
                  <!--                        selectedValuesMap[item]-->
                  <!--                          ? 'ag-icon-checkbox-checked'-->
                  <!--                          : 'ag-icon-checkbox-unchecked'-->
                  <!--                      "-->
                  <!--                    ></span>-->
                  <!--                  </div>-->
                  <div
                    class="mr-1 ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                    [ngClass]="selectedValuesMap[item] ? 'ag-checked' : ''"
                  ></div>
                  <span class="ag-filter-value">{{ item }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <section class="pt-2">
          <button
            type="button"
            class="c-btn ng-star-inserted"
            (click)="dropFilter()"
          >
            Сбросить
          </button>
          <!--          <button type="button" class="c-btn ng-star-inserted" (click)="applyFilter()">Ok</button>-->
        </section>
      </div>
    </div>
  </div>
</div>
