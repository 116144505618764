import { fullFieldsToChar } from '../utils';

export interface ObjectCompetencyResponceIntreface {
  competency_code: string;
  competency_id: number;
  competency_name: string;
  competency_name_2?: string;
  competency_desc: string;
  delete_flag: boolean;
}

export class ObjectCompetency {
  competency_code: string;
  competency_id: number;
  competency_name: string;
  competency_name_2?: string;
  competency_desc: string;
  delete_flag: boolean;
  competency_full: string;

  constructor(obj?: ObjectCompetencyResponceIntreface) {
    if (!obj) {
      return;
    }
    this.competency_id = obj.competency_id;
    this.competency_code = obj.competency_code;
    this.competency_name = obj.competency_name;
    this.competency_name_2 = obj.competency_name_2;
    this.competency_desc = obj.competency_desc;
    this.delete_flag = obj.delete_flag ? obj.delete_flag : false;
    this.competency_full = obj.competency_code
      ? fullFieldsToChar(
          [obj.competency_code, obj.competency_name, obj.competency_name_2],
          '/'
        )
      : null;
  }
}
