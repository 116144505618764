import { AppConfig } from '@itorum/models';

export interface OverallMediaModel {
  create_date: any;
  filename: string;
  filetype: string;
  thumb: string;
  file_path: string;
}

export interface OverallDefectObjectModels {
  answer_obj: any;
  media?: OverallMediaModel[];
  object_obj: any;
  object_type_obj: any;
  result: any;
  step_obj: any;
  task_obj: any;
  technik_obj: any;
}

export class OverallMedia implements OverallMediaModel {
  public create_date: string;
  public filename: string;
  public thumb: string;
  public filetype: string;
  public file_path: string;

  constructor(data: OverallMediaModel, appConfig: AppConfig) {
    this.create_date = data.create_date;
    this.filename = data.filename;
    this.filetype = data.filetype;
    this.file_path = appConfig.urlStaticPrefix + data.filename;
    this.thumb =
      appConfig.urlStaticPrefix + (data.thumb ? data.thumb : data.filename);
  }
}

// tslint:disable-next-line:no-empty-interface
export type OverallDefectObjectResponse = OverallDefectObjectModels;
export class OverallDefect implements OverallDefectObjectModels {
  public answer_obj: any;
  public media: OverallMediaModel[];
  public object_obj: any;
  public object_type_obj: any;
  public result: any;
  public step_obj: any;
  public task_obj: any;
  public technik_obj: any;

  constructor(data: OverallDefectObjectResponse, appConfig: AppConfig) {
    this.answer_obj = data.answer_obj;
    this.object_obj = data.object_obj;
    this.object_type_obj = data.object_type_obj;
    this.result = data.result;
    this.step_obj = data.step_obj;
    this.task_obj = data.task_obj;
    this.technik_obj = data.technik_obj;
    if (data.media) {
      this.media = data.media.map((el) => new OverallMedia(el, appConfig));
      this.media = this.media.reduce(
        (prevVal: any, currVal: any, currIndx: number, array: any) =>
          !(currIndx % 3)
            ? prevVal.concat([array.slice(currIndx, currIndx + 3)])
            : prevVal,
        []
      );
    } else {
      this.media = data.media;
    }
  }
}
