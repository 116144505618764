/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { InlineResponse2005 } from '../model/inlineResponse2005';
import { InlineResponse2006 } from '../model/inlineResponse2006';
import { JobsJobIdBody1 } from '../model/jobsJobIdBody1';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ExecutionJobService {
  protected basePath = 'https://dev.itorum-mr.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get jobs
   *
   * @param mobileTest Flag mobile testing
   * @param statuses Statuses ids
   * @param jobTypeIds job Type Ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiExecutionJobsGet(
    mobileTest: boolean,
    statuses?: Array<number>,
    jobTypeIds?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2005>;
  public apiExecutionJobsGet(
    mobileTest: boolean,
    statuses?: Array<number>,
    jobTypeIds?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2005>>;
  public apiExecutionJobsGet(
    mobileTest: boolean,
    statuses?: Array<number>,
    jobTypeIds?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2005>>;
  public apiExecutionJobsGet(
    mobileTest: boolean,
    statuses?: Array<number>,
    jobTypeIds?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (mobileTest === null || mobileTest === undefined) {
      throw new Error(
        'Required parameter mobileTest was null or undefined when calling apiExecutionJobsGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (mobileTest !== undefined && mobileTest !== null) {
      queryParameters = queryParameters.set('mobileTest', <any>mobileTest);
    }
    if (statuses) {
      statuses.forEach((element) => {
        queryParameters = queryParameters.append('statuses', <any>element);
      });
    }
    if (jobTypeIds) {
      jobTypeIds.forEach((element) => {
        queryParameters = queryParameters.append('jobTypeIds', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2005>(
      'get',
      `${this.basePath}/api/execution/jobs`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get job by id
   *
   * @param mobileTest Flag mobile testing
   * @param jobId Job id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiExecutionJobsJobIdGet(
    mobileTest: boolean,
    jobId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2006>;
  public apiExecutionJobsJobIdGet(
    mobileTest: boolean,
    jobId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2006>>;
  public apiExecutionJobsJobIdGet(
    mobileTest: boolean,
    jobId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2006>>;
  public apiExecutionJobsJobIdGet(
    mobileTest: boolean,
    jobId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (mobileTest === null || mobileTest === undefined) {
      throw new Error(
        'Required parameter mobileTest was null or undefined when calling apiExecutionJobsJobIdGet.'
      );
    }

    if (jobId === null || jobId === undefined) {
      throw new Error(
        'Required parameter jobId was null or undefined when calling apiExecutionJobsJobIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (mobileTest !== undefined && mobileTest !== null) {
      queryParameters = queryParameters.set('mobileTest', <any>mobileTest);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse2006>(
      'get',
      `${this.basePath}/api/execution/jobs/${encodeURIComponent(
        String(jobId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update job
   *
   * @param mobileTest Flag mobile testing
   * @param jobId Job id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiExecutionJobsJobIdPut(
    mobileTest: boolean,
    jobId: number,
    body?: JobsJobIdBody1,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse2006>;
  public apiExecutionJobsJobIdPut(
    mobileTest: boolean,
    jobId: number,
    body?: JobsJobIdBody1,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse2006>>;
  public apiExecutionJobsJobIdPut(
    mobileTest: boolean,
    jobId: number,
    body?: JobsJobIdBody1,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse2006>>;
  public apiExecutionJobsJobIdPut(
    mobileTest: boolean,
    jobId: number,
    body?: JobsJobIdBody1,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (mobileTest === null || mobileTest === undefined) {
      throw new Error(
        'Required parameter mobileTest was null or undefined when calling apiExecutionJobsJobIdPut.'
      );
    }

    if (jobId === null || jobId === undefined) {
      throw new Error(
        'Required parameter jobId was null or undefined when calling apiExecutionJobsJobIdPut.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (mobileTest !== undefined && mobileTest !== null) {
      queryParameters = queryParameters.set('mobileTest', <any>mobileTest);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse2006>(
      'put',
      `${this.basePath}/api/execution/jobs/${encodeURIComponent(
        String(jobId)
      )}`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
