import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('basePath');
export const ANOTHER_BASE_PATH = new InjectionToken<string>('anotherBasePath');
export const VOICE_BASE_PATH = new InjectionToken<string>('voiceBasePath');
export const COLLECTION_FORMATS = {
  csv: ',',
  tsv: '   ',
  ssv: ' ',
  pipes: '|',
};
