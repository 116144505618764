/*
 * Translate loader class
 */

import { from as observableFrom, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

export class I18nTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return observableFrom(
      // System.import(`./../../../assets/i18n/${lang}.json`)
      import(`./../../../assets/i18n/${lang}.json`)
    );
  }
}
