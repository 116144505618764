import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoComponent } from './components/demo/demo.component';
import { InputComponent } from './components/input/input.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { PageComponent } from './components/page/page.component';
import { PageTitleComponent } from './components/page/components/page-title/page-title.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConferenceDrawComponent } from './components/conference-draw/conference-draw.component';
import { ImageService, StorageService } from '@itorum/services';
import { NgxMaskModule } from 'ngx-mask';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { PageToplineComponent } from './components/page/components/page-topline/page-topline.component';
import { PageSwitchComponent } from './components/page/components/page-switch/page-switch.component';
import { PageItemsComponent } from './components/page/components/page-items/page-items.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatepickerSingleComponent } from './components/datepicker-single/datepicker-single.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { TextareaComponent } from './components/textarea/textarea.component';
import { MediasGalleryComponent } from './components/medias-gallery/medias-gallery.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SelectComponent } from './components/select/select.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FilesGalleryComponent } from './components/files-gallery/files-gallery.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FilterByTypePipe } from './components/calendar/pipes/filter-by-type.pipe';
import { CountPipe } from './components/calendar/pipes/count.pipe';
import { FilterByStatusPipe } from './components/calendar/pipes/filter-by-status.pipe';
import { RouterModule } from '@angular/router';
import { StatMultiGraphComponent } from './components/stat-multi-graph/stat-multi-graph.component';
import { MatIconModule } from '@angular/material/icon';
import { DeviceMonitorModule } from './modules/device-monitor/device-monitor.module';
import { CutZonePipe } from './pipes/cut-zone.pipe';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';
import { LocationPickerCoreComponent } from './components/location-picker-core/location-picker-core.component';
import { GetExtensionPipe } from './pipes/get-extension.pipe';
import { FilterMediaPipe } from './pipes/filter-media.pipe';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NodeGalleryComponent } from './components/node-gallerey/node-gallery.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RouterModule,
    MatIconModule,
    DeviceMonitorModule,
    MatProgressBarModule,
  ],
  declarations: [
    DemoComponent,
    InputComponent,
    ButtonComponent,
    LangSwitcherComponent,
    PageComponent,
    PageTitleComponent,
    FooterComponent,
    ConferenceDrawComponent,
    ArrayFilterPipe,
    PageToplineComponent,
    PageSwitchComponent,
    PageItemsComponent,
    TextareaComponent,
    DatepickerComponent,
    DatepickerSingleComponent,
    TimepickerComponent,
    MediasGalleryComponent,
    CheckboxComponent,
    SelectComponent,
    SearchSelectComponent,
    CarouselComponent,
    FilesGalleryComponent,
    CalendarComponent,
    FilterByTypePipe,
    CountPipe,
    FilterByStatusPipe,
    StatMultiGraphComponent,
    CutZonePipe,
    LocationPickerComponent,
    LocationPickerCoreComponent,
    GetExtensionPipe,
    FilterMediaPipe,
    SelectSearchComponent,
    NodeGalleryComponent,
  ],
  exports: [
    DemoComponent,
    InputComponent,
    ButtonComponent,
    LangSwitcherComponent,
    PageComponent,
    PageTitleComponent,
    PageToplineComponent,
    FooterComponent,
    ConferenceDrawComponent,
    ArrayFilterPipe,
    PageSwitchComponent,
    PageItemsComponent,
    TextareaComponent,
    DatepickerComponent,
    DatepickerSingleComponent,
    TimepickerComponent,
    MediasGalleryComponent,
    CheckboxComponent,
    SelectComponent,
    SearchSelectComponent,
    CarouselComponent,
    FilesGalleryComponent,
    CalendarComponent,
    StatMultiGraphComponent,
    DeviceMonitorModule,
    CutZonePipe,
    LocationPickerComponent,
    GetExtensionPipe,
    FilterMediaPipe,
    SelectSearchComponent,
    NodeGalleryComponent,
  ],
  providers: [ImageService, StorageService, GetExtensionPipe, FilterMediaPipe],
})
export class UiModule {}
