import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMedia',
})
export class FilterMediaPipe implements PipeTransform {
  transform(value: any[], ...args: unknown[]): any[] {
    // console.log('FilterMediaPipe value =>', value);
    return value?.filter((el) => {
      const fileName = el?.file_info?.file_name
        ? el.file_info.file_name
        : el?.file_name;

      if (fileName) {
        const [_, ext] = fileName.split('.');
        return !args.includes(ext);
      } else {
        return false;
      }
    });
  }
}
