<div class="flex flex-row justify-between mt-2">
  <div class="pt-2">
    {{ user?.fullName }}
  </div>
  <div class="pr-1 py-1">
    <i
      *ngIf="!isInviteSended; else awaiter"
      class="icon-call_black text-green font-bold text-2xl cursor-pointer hover:text-green-200"
      (click)="sendInvite()"
    ></i>
    <ng-template #awaiter>
      <div
        style="height: 34px; width: 34px"
        *ngIf="!isInviteAccepted; else member_in_call"
      >
        <mat-progress-spinner
          class="my-spinner"
          [color]="'primary'"
          [mode]="'indeterminate'"
          [value]="65"
          [diameter]="34"
          [strokeWidth]="4"
        >
        </mat-progress-spinner>
      </div>
      <ng-template #member_in_call>
        <i class="icon-phone_in_talk hover:text-red-500"></i>
      </ng-template>
    </ng-template>
  </div>
</div>
