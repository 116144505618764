/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ApiStepBody } from '../model/apiStepBody';
import { InlineResponse20010 } from '../model/inlineResponse20010';
import { InlineResponse20011 } from '../model/inlineResponse20011';
import { InlineResponse20012 } from '../model/inlineResponse20012';
import { InlineResponse20013 } from '../model/inlineResponse20013';
import { StepIdMediaBody } from '../model/stepIdMediaBody';
import { StepStepIdBody } from '../model/stepStepIdBody';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { CopyStepResponse } from '../model/copyStepResponse';
import { StepCopyBody } from '../model/stepCopyBody';

@Injectable()
export class StepService {
  protected basePath = 'https://dev.itorummr.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get steps by action id
   *
   * @param actionId Action id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepActionActionIdGet(
    actionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20013>;
  public apiStepActionActionIdGet(
    actionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20013>>;
  public apiStepActionActionIdGet(
    actionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20013>>;
  public apiStepActionActionIdGet(
    actionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (actionId === null || actionId === undefined) {
      throw new Error(
        'Required parameter actionId was null or undefined when calling apiStepActionActionIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20013>(
      'get',
      `${this.basePath}/api/step/action/${encodeURIComponent(
        String(actionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get steps by action template id
   *
   * @param actionTemplateId Action template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepActionTemplateActionTemplateIdGet(
    actionTemplateId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20013>;
  public apiStepActionTemplateActionTemplateIdGet(
    actionTemplateId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20013>>;
  public apiStepActionTemplateActionTemplateIdGet(
    actionTemplateId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20013>>;
  public apiStepActionTemplateActionTemplateIdGet(
    actionTemplateId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (actionTemplateId === null || actionTemplateId === undefined) {
      throw new Error(
        'Required parameter actionTemplateId was null or undefined when calling apiStepActionTemplateActionTemplateIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20013>(
      'get',
      `${this.basePath}/api/step/action-template/${encodeURIComponent(
        String(actionTemplateId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create step
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepPost(
    body?: ApiStepBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20010>;
  public apiStepPost(
    body?: ApiStepBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20010>>;
  public apiStepPost(
    body?: ApiStepBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20010>>;
  public apiStepPost(
    body?: ApiStepBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20010>(
      'post',
      `${this.basePath}/api/step`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Copy step
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepCopyPost(
    body?: StepCopyBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CopyStepResponse>;
  public apiStepCopyPost(
    body?: StepCopyBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CopyStepResponse>>;
  public apiStepCopyPost(
    body?: StepCopyBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CopyStepResponse>>;
  public apiStepCopyPost(
    body?: StepCopyBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<CopyStepResponse>(
      'post',
      `${this.basePath}/api/step/copy`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * @deprecated
   * Copy step
   * @param stepId Step id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdCopyGet(
    stepId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20010>;
  public apiStepStepIdCopyGet(
    stepId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20010>>;
  public apiStepStepIdCopyGet(
    stepId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20010>>;
  public apiStepStepIdCopyGet(
    stepId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdCopyGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20010>(
      'get',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}/copy`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete step
   *
   * @param stepId Step id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdDelete(
    stepId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStepStepIdDelete(
    stepId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStepStepIdDelete(
    stepId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStepStepIdDelete(
    stepId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get full step
   *
   * @param stepId Step id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdGet(
    stepId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20010>;
  public apiStepStepIdGet(
    stepId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20010>>;
  public apiStepStepIdGet(
    stepId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20010>>;
  public apiStepStepIdGet(
    stepId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20010>(
      'get',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get step media
   *
   * @param stepId Step id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdMediaGet(
    stepId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20011>;
  public apiStepStepIdMediaGet(
    stepId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20011>>;
  public apiStepStepIdMediaGet(
    stepId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20011>>;
  public apiStepStepIdMediaGet(
    stepId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdMediaGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20011>(
      'get',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}/media`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete step media
   *
   * @param stepId Step id
   * @param mediaId Media id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdMediaMediaIdDelete(
    stepId: number,
    mediaId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStepStepIdMediaMediaIdDelete(
    stepId: number,
    mediaId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStepStepIdMediaMediaIdDelete(
    stepId: number,
    mediaId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStepStepIdMediaMediaIdDelete(
    stepId: number,
    mediaId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdMediaMediaIdDelete.'
      );
    }

    if (mediaId === null || mediaId === undefined) {
      throw new Error(
        'Required parameter mediaId was null or undefined when calling apiStepStepIdMediaMediaIdDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/step/${encodeURIComponent(
        String(stepId)
      )}/media/${encodeURIComponent(String(mediaId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Set media
   *
   * @param stepId Step id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdMediaPost(
    stepId: number,
    body?: StepIdMediaBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20012>;
  public apiStepStepIdMediaPost(
    stepId: number,
    body?: StepIdMediaBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20012>>;
  public apiStepStepIdMediaPost(
    stepId: number,
    body?: StepIdMediaBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20012>>;
  public apiStepStepIdMediaPost(
    stepId: number,
    body?: StepIdMediaBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdMediaPost.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20012>(
      'post',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}/media`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update step
   *
   * @param stepId Step id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepStepIdPut(
    stepId: number,
    body?: StepStepIdBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20010>;
  public apiStepStepIdPut(
    stepId: number,
    body?: StepStepIdBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20010>>;
  public apiStepStepIdPut(
    stepId: number,
    body?: StepStepIdBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20010>>;
  public apiStepStepIdPut(
    stepId: number,
    body?: StepStepIdBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepStepIdPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20010>(
      'put',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete step result
   *
   * @param resultId Result id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepResultDelete(
    resultId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStepResultDelete(
    resultId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStepResultDelete(
    resultId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStepResultDelete(
    resultId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (resultId === null || resultId === undefined) {
      throw new Error(
        'Required parameter resultId was null or undefined when calling apiStepResultDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/step/result/${encodeURIComponent(
        String(resultId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete step
   *
   * @param stepId Step id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiStepDelete(
    stepId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiStepDelete(
    stepId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiStepDelete(
    stepId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiStepDelete(
    stepId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (stepId === null || stepId === undefined) {
      throw new Error(
        'Required parameter stepId was null or undefined when calling apiStepDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/step/${encodeURIComponent(String(stepId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
