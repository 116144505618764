import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User, AppConfig } from '@itorum/models';
import { Router } from '@angular/router';

export interface IUserRole {
  ADMINISTRATOR_KIP: number;
  MANAGER: number; // Руководитель
  TECHNIC: number;
  REMOTE_EXPERT: number;
  DEVELOPER: number;
  INSPECTION_MEMBER: number;
}

interface IPermissionRole {
  role_id: number;
  role_name: string; // "Администратор КИП",
  code: string; // "superadmin"
}

@Injectable({
  providedIn: 'root',
})
export class PermissionRolesService {
  private _EUserRole: IUserRole = null;
  private _permissionRoles: IPermissionRole[] = [];

  get EUserRole(): IUserRole {
    return this._EUserRole;
  }

  get PermissionRoles(): IPermissionRole[] {
    return this._permissionRoles;
  }

  private setEUserRole(value: IPermissionRole[], roleCodes?: any) {
    const codes = roleCodes ? roleCodes : {};
    console.log('setEUserRole codes =>', codes);
    enum roles {
      ADMINISTRATOR_KIP = value.find(
        (r) => r.code === (codes?.superadmin ?? 'superadmin')
      )?.role_id,
      MANAGER = value.find((r) => r.code === (codes?.manager ?? 'manager'))
        ?.role_id, // Руководитель
      TECHNIC = value.find((r) => r.code === (codes?.technic ?? 'technic'))
        ?.role_id,
      REMOTE_EXPERT = value.find(
        (r) => r.code === (codes?.remote_expert ?? 'remote-expert')
      )?.role_id,
      DEVELOPER = value.find(
        (r) => r.code === (codes?.developer ?? 'developer')
      )?.role_id,
      INSPECTION_MEMBER = value.find(
        (r) => r.code === (codes?.committe_member ?? 'committe-member')
      )?.role_id,
    }

    this._EUserRole = roles as IUserRole;
  }

  constructor(private router: Router, private http: HttpClient) {}

  private getPermissionRoles(appConfig?: AppConfig) {
    const url = '/api/permission-roles';
    return this.http.get(url).pipe(
      map((res) => {
        // console.log('getPermissionRoles ==>', res);
        if ((res as any).status) {
          this._permissionRoles = (res as any).data;
          this.setEUserRole((res as any).data, appConfig?.codes);
          return this._EUserRole;
        } else {
          // this.toastrService.error('ошибка получения идентификаторов ролей');
          return null;
        }
      })
    );
  }

  getRoleName(role_id: number): string {
    return this.PermissionRoles.find((p) => p.role_id === role_id).role_name;
  }

  initRoles(appConfig?: AppConfig, forceRedirect?: boolean): Promise<any> {
    console.log('initRoles start==>');
    return this.getPermissionRoles(appConfig)
      .toPromise()
      .then(() => {
        // console.log('some then doings');
        console.log('initRoles forceRedirect =>', forceRedirect);
        if (forceRedirect) {
          return this.router.navigateByUrl('/main/home')
            .then((result) => {
              console.log('zayebis =>', result);
              return result;
            })
            .catch((result) => {
              console.error('pizdec =>', result);
              return result;
            });
        }
      })
      .catch(() => {
        // console.log('some catch doings');
        this.router.navigateByUrl('/oops');
      });
  }

  private checkRole(user: User, role: number): boolean {
    if (user?.roles) {
      const mach = user.roles.find((u_role) => u_role.role_id === role);
      return mach !== void 0;
    } else {
      return undefined;
    }
  }

  /**
   * Если у пользователя есть роль Руководитель - возвращает true,
   * иначе false.
   * Если в объекте не определён массив roles вернёт undefined
   */
  isManager(user: User): boolean {
    return this.checkRole(user, this.EUserRole.MANAGER);
  }

  /**
   * Если у пользователя есть роль Разработчик - возвращает true,
   * иначе false.
   * Если в объекте не определён массив roles вернёт undefined
   */
  isDeveloper(user: User): boolean {
    return this.checkRole(user, this.EUserRole.DEVELOPER);
  }

  /**
   * Если у пользователя есть роль Техник(Специалист) - возвращает true,
   * иначе false.
   * Если в объекте не определён массив roles вернёт undefined
   */
  isTechnician(user: User): boolean {
    return this.checkRole(user, this.EUserRole.TECHNIC);
  }

  /**
   * Возвращает true, если пользователь является администратором
   * @param {User} user - Пользователь - пользовательский объект, который вы хотите проверить
   * @returns Логическое значение.
   */
  isAdmin(user: User): boolean {
    return this.checkRole(user, this.EUserRole.ADMINISTRATOR_KIP);
  }

  getRoleCode(role_id: number) {
    return this.PermissionRoles.find((p) => p.role_id === role_id).code;
  }
}
