import { IMeasureInfoModel, MeasureInfo } from './measure-info.model';
import { IObjectType } from './object-type.model';
import { ObjectType } from './object-type.model';

export interface IStep {
  taskId: number;
  code: string;
  objectType: any; // fixme must be determined
  text: string;
  ifMakePhoto?: boolean;
  ifMakeVideo?: boolean;
  record_defect?: boolean;
  iaArchived: boolean;
  takeMeasure?: boolean;
  editable: boolean;
  refTaskId?: any;
  measureInfo?: IMeasureInfoModel;
}

interface IGetStepListResponse extends IStep {
  id: number;
  // code: string;
  editable: boolean;
  iaArchived: boolean;
  ifMakePhoto: boolean;
  ifMakeVideo: boolean;
  record_defect: boolean;
  takeMeasure: boolean;
  // text: string;
  objectType: IObjectType;
  photo: {
    media_id: number;
    file_name: string;
    thumb?: string;
  }[];
  video: {
    media_id: number;
    file_name: string;
    thumb?: string;
  }[];
  refTaskId: any;
  // measureInfo?: MeasureInfo;
}

export class Step implements IGetStepListResponse {
  public id: number;
  public taskId: number;
  public code: string;
  public editable: boolean;
  public iaArchived: boolean;
  public ifMakePhoto: boolean;
  public ifMakeVideo: boolean;
  public record_defect: boolean;
  public takeMeasure: boolean;
  public text: string;
  public refTaskId: any;
  public objectType: IObjectType;
  public photo: {
    media_id: number;
    file_name: string;
    thumb?: string;
  }[];
  public video: {
    media_id: number;
    file_name: string;
    thumb?: string;
  }[];
  public concat_fields: string;
  public measureInfo?: IMeasureInfoModel;

  constructor(data: IGetStepListResponse) {
    this.id = Number(data.id);
    this.taskId = data.taskId;
    this.code = data.code;
    this.text = data.text;
    this.ifMakePhoto = data.ifMakePhoto;
    this.ifMakeVideo = data.ifMakeVideo;
    this.record_defect = data.record_defect;
    this.iaArchived = data.iaArchived;
    this.takeMeasure = data.takeMeasure;
    this.objectType = new ObjectType(data.objectType);
    this.refTaskId = data.refTaskId;
    this.concat_fields = `${data.code}  ${data.text}`;
    this.photo = data.photo ? Array.from(data.photo) : [];
    this.video = data.video ? Array.from(data.video) : [];
    this.measureInfo = MeasureInfo.createFromIMeasureInfoModel(
      data.measureInfo
    );
  }

  /**
   * Этот костыль служит для создания из snake case данных, класса в camel case
   * @param data
   */
  public static create(data): Step {
    const step = new Step(data);
    step.measureInfo = new MeasureInfo(data.measureInfo);
    return step;
  }

  public update(data: IStep): void {
    Object.assign(this, data);
  }
}

export interface IMeasure {
  step_measure_id?: number;
  step_id?: number;
  measure_name?: string;
  measure_unit?: string;
  measure_benchmark?: any;
  positive_deviation?: any;
  negative_deviation?: any;
  deviation_control?: boolean;
  create_date?: string; // "2019-07-18T13:43:20.601791",
  create_user_id?: number;
}

export class TaskStep {
  /** "шаг" или "группа" */
  public row_type: string;

  /** ID шага или группы */
  public id: number;

  /** порядковый номер */
  public order_num: number;

  /** наименование группы */
  public group_name: string;

  /** код шага */
  public code: string;

  /** вопрос/действие для шага */
  public text: string;

  /** требуется фото */
  public make_photo: boolean;

  /** требуется видео */
  public make_video: boolean;
  private ref_task: string;
  private step_measure: IMeasure;

  constructor(data: {
    row_type: string;
    id: number;
    order_num: number;
    group_name?: string;
    code: string;
    text: string;
    make_photo?: boolean;
    make_video?: boolean;
    ref_task?: string;
    step_measure?: IMeasure;
  }) {
    this.row_type = data.row_type;
    this.id = data.id;
    this.order_num = data.order_num;
    this.group_name = data.group_name;
    this.code = data.code;
    this.text = data.text;
    this.make_photo = data.make_photo;
    this.make_video = data.make_video;
    this.ref_task = data.ref_task;
    this.step_measure = data.step_measure;
  }

  public static fromStep(step: Step, order_num = null): TaskStep {
    return new TaskStep(
      Object.assign({ row_type: 'шаг', order_num: order_num }, step)
    );
  }
}
