import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData, DatePipe } from '@angular/common';
import { SystemSettingsService } from '../services/system-settings.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

registerLocaleData(localeRu, 'ru');

@Pipe({
  name: 'itime',
})
export class ItimePipe implements PipeTransform, OnDestroy {
  private format: string;

  constructor(
    private _systemSettings: SystemSettingsService,
    private datePipe: DatePipe
  ) {
    this._systemSettings.settings$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        if (settings && settings.get('time_format')) {
          this.format = settings.get('time_format').param_value;
        }
      });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {
    //DO NOT delete this
  }

  transform(value: any, args?: any): any {
    // console.log(this.format);
    return isNaN(new Date(value).getTime())
      ? value
      : this.datePipe.transform(value, this.format, 'ru');
  }
}
