import { AppConfig } from '@itorum/models';

export interface TaskMaterialInterface {
  task_material_id: number;
  file_name: string;
  file_path: string;
  thumb: string;
  create_date: string;
  file_size: any;
}

// tslint:disable-next-line:no-empty-interface
type TaskMaterialResponse = TaskMaterialInterface;

export class TaskMaterial implements TaskMaterialInterface {
  public task_material_id: number;
  public file_name: string;
  public file_path: string;
  public thumb: string;
  public create_date: string;
  public type: string;
  public file_size: any;
  constructor(data: TaskMaterialResponse, appConfig: AppConfig) {
    this.task_material_id = data.task_material_id;
    this.file_name = data.file_name;
    this.file_path = appConfig.urlStaticPrefix + data.file_path;
    this.thumb =
      appConfig.urlStaticPrefix + (data.thumb ? data.thumb : data.file_name);
    this.create_date = data.create_date || new Date().toISOString();
    this.type = data.file_name.split('.').pop().toLowerCase();
    this.file_size =
      data.file_size / 1024 > 1024
        ? Math.ceil(data.file_size / 1024 / 1024) + ' МБ'
        : Math.ceil(data.file_size / 1024) + ' КБ';
  }
}
