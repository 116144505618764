/**
 * Он принимает массив объектов и имя поля и возвращает максимальное значение этого поля в массиве.
 * @param {string} field - поле, чтобы найти максимальное значение
 * @param {any[]} entities - массив сущностей для поиска
 * @returns Максимальное значение поля в массиве сущностей.
 */
export const findMaxBy = (field: string, entities: any[]): number => {
  if (!entities?.length) {
    return 0;
  }
  return entities
    .map((el) => +el[field])
    .reduce((prev, cur) => Math.max(prev, cur));
};

/**
 * Он принимает массив объектов и имя поля и возвращает сумму значений этого поля в массиве.
 * @param {string} field - поле для суммирования
 * @param {any[]} entities - массив сущностей для суммирования
 * @returns Сумма значений поля в массиве сущностей.
 */
export const sumBy = (field: string, entities: any[]): number => {
  if (!entities?.length) {
    return 0;
  }
  return entities.map((el) => +el[field]).reduce((prev, cur) => +prev + +cur);
};
