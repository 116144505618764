import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SystemSettingsService, UserService } from './shared';
import { StorageService } from '@itorum/services';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '@shared/services/app-config.service';
import { ThemeService } from '@shared/services/theme.service';

@Component({
  selector: 'itorum-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public loadingBarColor: string;

  constructor(
    public user: UserService,
    public translateService: TranslateService,
    private sysSettingService: SystemSettingsService,
    private storageService: StorageService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public appConfigService: AppConfigService,
    public themeService: ThemeService
  ) {
    this._document
      .getElementById('appFavicon')
      .setAttribute('href', this.appConfigService.appConfig.favicon);
    this.loadingBarColor = this.themeService.getProperty('--primary-color');

    // Language settings
    translateService.setDefaultLang(
      this.appConfigService.appConfig.defaultLanguage
    );
    const currentLang = this.storageService.getCurrentLanguage(
      this.appConfigService.appConfig.defaultLanguage
    );
    translateService.use(currentLang);
    console.log(`Current locale: ${translateService.currentLang}`);
  }

  ngOnInit() {
    this.sysSettingService.retrieveAllOnce();
    // console.log('import.meta ==>', import.meta);
    // this.initWorker();
  }

  // private initWorker() {
  //   if (typeof Worker !== 'undefined') {
  //     // console.log('ass ==>', new URL('~/voice-recorder.worker', import.meta.url));
  //     // Create a new
  //     // const worker = new Worker(new URL('./voice-recorder.worker'), {type: 'module'});
  //     const worker = new Worker('./webworkers/voice-recorder.worker', {type: 'module'});
  //     worker.onmessage = ({ data }) => {
  //       console.log(`page got message: ${data}`);
  //     };
  //     worker.postMessage('hello');
  //   } else {
  //     // Web workers are not supported in this environment.
  //     // You should add a fallback so that your program still executes correctly.
  //   }
  // }
}
