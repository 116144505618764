export interface IFullPhoneData {
  countryCode: string;
  phone: string;
}

export const merge4save = (countryCode, phone, CountryFullData) => {
  console.log('merge4save countryCode =>', countryCode);
  console.log('merge4save phone =>', phone);
  console.log('merge4save CountryFullData =>', CountryFullData);
  const data: IFullPhoneData = {
    phone,
    countryCode,
  };
  return JSON.stringify(data);
};

export const parse4display = (phone, part: 'countryCode' | 'phone') => {
  const parsed: IFullPhoneData = JSON.parse(phone);
  if (parsed?.countryCode) {
    switch (part) {
      case 'countryCode':
        return parsed?.countryCode;
      case 'phone':
        return parsed?.phone;
      default:
        return void 0;
    }
  } else {
    switch (part) {
      case 'countryCode':
        return 'RU';
      case 'phone':
        return phone ? phone.slice(1, phone.length - 1) : '';
      default:
        return void 0;
    }
  }
};

export const merge4display = (user, list: { [key: string]: string }) => {
  const { countryCode, phone } = user.phone_details;

  if (!countryCode || !phone) {
    return '';
  }

  // let cut, result;
  // try {
  //   cut = phone.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
  //   result = `(${cut[1]}) ${cut[2]}-${cut[3]}-${cut[4]}`;
  // } catch (e) {
  //   result = phone;
  // }

  return `+${list[countryCode]}-${phone}`;
};
