export interface IFieldSelectOption {
  value: string | number;
  label: string;
}

export interface IFieldSelectOptions {
  value: string | number;
  label: string;
  phone: string;
  position: string;
}

export interface IFieldExtendedOption extends IFieldSelectOption {
  iconClass: string;
  subLabel: string;
}

export interface TypedOption extends IFieldExtendedOption {
  optionType: OptionType;
}

export interface WithMeasureId extends TypedOption {
  measureId?: string;
}

export enum OptionType {
  MEASURE,
  DEFECT,
  QUESTION,
}
