import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { DetachModeService } from 'app/common/services/detach-mode.service';

@Injectable({
  providedIn: 'root',
})
export class RoomStrategy implements RouteReuseStrategy {
  constructor(private detachModeService: DetachModeService) {}

  private handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // console.log('RoomStrategy shouldDetach route =>', route);

    // return route?.routeConfig?.path === 'keep-alive-test';

    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    }

    return (
      this.getUrl(route) === 'room/:id' &&
      this.detachModeService.getDetachModeStatus
    );

    // if (!route.routeConfig || route.routeConfig.loadChildren) {
    //   return false;
    // }
    //
    // let shouldReuse = false;
    //
    // console.log('checking if this route should be re used or not', route);
    //
    // if (route.routeConfig.data) {
    //
    //   route.routeConfig.data.reuse ? shouldReuse = true : shouldReuse = false;
    //
    // }
    //
    // return shouldReuse;
  }
  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle
  ): void {
    // console.log('RoomStrategy store route =>', route);
    // console.log('RoomStrategy store detachedTree =>', detachedTree);
    // console.log('RoomStrategy storing handler');

    if (detachedTree) {
      const instance = (detachedTree as any).componentRef.instance;
      // instance?.aliveKeeperService?.save(instance);
      this.handlers[this.getUrl(route)] = detachedTree;
      this.detachModeService.saveInstance(this.getInstance(this.getUrl(route)));
    }
    // console.log('RoomStrategy handlers =>', this.handlers);
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // console.log('RoomStrategy shouldAttach route =>', route);
    const result = !!this.handlers[this.getUrl(route)];
    // console.log('RoomStrategy shouldAttach result is =>', result);
    return result;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // console.log('RoomStrategy retrieve route =>', route);
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }
    return this.handlers[this.getUrl(route)];
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // console.log('RoomStrategy shouldReuseRoute future =>', future);
    // console.log('RoomStrategy shouldReuseRoute curr =>', curr);

    // return future?.routeConfig?.path === 'keep-alive-test';
    const check =
      this.getUrl(future) === 'room/:id' &&
      this.detachModeService.getDetachModeStatus;
    if (check) {
      this.detachModeService.deactivateDetachMode();
      this.detachModeService.dropInstance();
    }

    return false;

    // if(future.routeConfig !== curr.routeConfig) {
    //   return false;
    // } else if(Object.keys(future.params).length !== Object.keys(curr.params).length ||
    //   Object.keys(future.queryParams).length !== Object.keys(curr.queryParams).length) {
    //   return false;
    // } else {
    //   return Object.keys(future.params).every(k => future.params[k] === curr.params[k]) &&
    //     Object.keys(future.queryParams).every(k => future.queryParams[k] === curr.queryParams[k]);
    // }
  }

  getUrl(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig) {
      const url = route.routeConfig.path;

      // console.log('RoomStrategy returning url', url);

      return url;
    }
  }

  getInstance(path: string) {
    const detachedTree = this.handlers[path];
    return (detachedTree as any)?.componentRef?.instance;
  }
}
