import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfig} from "@itorum/models";
import {AppConfigService} from "@shared/services/app-config.service";
import {PermissionRolesService} from "app/common/services/permission-roles.service";

@Component({
  selector: 'itorum-oops',
  templateUrl: './oops.component.html',
  styleUrls: ['./oops.component.scss'],
})
export class OopsComponent implements OnInit, OnDestroy {
  private conf: AppConfig;
  private index: number;

  constructor(
    private permissionRolesService: PermissionRolesService,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {

    console.log('OopsComponent ngOnInit');

    this.index = setInterval(() => {
      this.watchDog()
        .then((result) => {
          console.log('watchDog clearInterval', result);
          clearInterval(this.index);
        })
        .catch(() => {
          console.error('watchDog catch');
        })
    },5000);


  }

  ngOnDestroy(): void {
    console.error('OopsComponent destroyed');
  }

  async watchDog() {
    if (!this.conf) {
      this.conf = await this.appConfigService.initAppSettings();
    }
    console.log('watchDog this.conf =>', this.conf)
    return this.permissionRolesService.initRoles(this.conf, true);
  }
}
