<div
  class="mon-display-node"
  (mouseover)="model.setHovered()"
  (mouseout)="model.setHovered(false)"
>
  <div class="content">
    <!--    <div>selectedTags : {{ selectedTags[0] | json }}</div>-->
    <article
      class="flex flex-col w-full justify-between items-baseline py-2 border-b"
      *ngFor="let tag of selectedTags; let i = index"
    >
      <!--      <span>{{ 'device-monitor.mon-display.track-value' | translate }}</span>-->
      <div class="flex justify-center w-full mb-2">
        <span class="text-6xl">{{ tag?.name }}</span>
        <!--        <span class="pl-2">-->
        <!--          <i class="icon-trash_can_black text-primary hover:text-errors text-xl" (click)="releaseSelected(i)"></i>-->
        <!--        </span>-->
      </div>
      <div class="flex justify-center w-full">
        <div
          class="bg-yellow-300 rounded-2xl text-9xl px-8 py-1 mx-1"
          [ngClass]="{
            'bg-green': myValue > tag?.hiVal,
            'bg-errors': myValue < tag?.lowVal
          }"
        >
          {{ myValue ? myValue : autogen }} {{ tag?.unit }}
        </div>
      </div>
    </article>

    <article
      class="flex w-full justify-center mt-2"
      *ngIf="!selectedTags?.length"
    >
      <i
        class="icon-tf_add_vector text-2xl text-primary"
        [ngStyle]="{ visibility: isShowSearchSources ? 'hidden' : '' }"
        (click)="addObservable()"
      ></i>
    </article>
    <ng-template [ngIf]="isShowSearchSources">
      <div
        class="absolute bg-white border rounded"
        style="left: 125px; bottom: -55px"
      >
        <itorum-sources-search
          [free]="(tagsQuery.getTagsState() | async)?.free"
          (doClose)="isShowSearchSources = false"
          (addTag)="addTag($event)"
        ></itorum-sources-search>
      </div>
    </ng-template>
  </div>
</div>
<div class="ports" style="position: absolute; bottom: 0; left: 43%">
  <ng-template #portsLayer></ng-template>
</div>
