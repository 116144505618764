import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng4-click-outside';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ITableModule } from './components/table-filter/i-table.module';

import { FilterPipe } from './pipes/filter.pipe';
import { StatusAndIdFilterPipe } from './pipes/status-and-id-filter.pipe';
import { RoleFilterPipe } from './pipes/role-filter.pipe';
import { ManagerFilterPipe } from './pipes/manager-filter.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { IdatePipe } from './pipes/idate.pipe';
import { ItimePipe } from './pipes/itime.pipe';
import { IdatetimePipe } from './pipes/idatetime.pipe';

import { OrientDirective } from './directives/orient.directive';
import { AddClassDirective } from './directives/addClass.directive';

import { ItrLoaderComponent } from './components/itr-loader/itr-loader.component';
import { GalleryComponent } from './components/gallery/gallery.component';

import { RoleFormComponent } from './components/role-form/role-form.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { ItrMultiSelectComponent } from './components/multi-select/multi-select.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { ItrSelectComponent } from './components/itr-select/itr-select.component';
import { ItrSelectSingleSearchComponent } from './components/itr-single-search/itr-select-single-search.component';
import { FieldsModule } from './components/fields/fields.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedServicesModule } from '@shared/services/shared-services.module';
import { StopwatchPipe } from './pipes/stopwatch.pipe';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { ItrTableAgComponent } from './modules/table/components/itr-table-ag/itr-table-ag.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AudiotypePipe } from './pipes/audiotype.pipe';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselSlideComponent } from './components/carousel/components/carousel-slide/carousel-slide.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { GalleryPreviewComponent } from './components/gallery-preview/gallery-preview.component';
import { StepListComponent } from './components/step-list/step-list.component';
import { ExternalFiltersComponent } from '@shared/modules/table/components/itr-table-ag/components/external-filters/external-filters.component';
import { CustomFilterComponent } from '@shared/modules/table/components/itr-table-ag/components/custom-filter/custom-filter.component';
import { UiModule } from '@itorum/ui';
import { VideoCallContainerComponent } from '@shared/components/video-call-container/video-call-container.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { IntentAddmemberComponent } from './components/video-call-container/components/intent-add-member/intent-add-member.component';
import { PlanCallPreambulaModalComponent } from './components/plan-call-preambula-modal/plan-call-preambula-modal.component';
import { CancelEventModalComponent } from './components/cancel-event-modal/cancel-event-modal.component';
import { ItorumSelectSearchCustomComponent } from './components/plan-call-preambula-modal/itorum-select-search-custom/itorum-select-search-custom.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ClickOutsideModule,
    NgScrollbarModule,
    ITableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    FieldsModule,
    BsDatepickerModule,
    SharedServicesModule,
    MatSliderModule,
    MatMenuModule,
    AgGridModule.withComponents([CustomFilterComponent]),
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    CarouselModule.forRoot(),
    UiModule,
    DragDropModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCardModule,
  ],
  declarations: [
    FilterPipe,
    StatusAndIdFilterPipe,
    RoleFilterPipe,
    ManagerFilterPipe,
    PhonePipe,
    SortPipe,
    ItrLoaderComponent,
    GalleryComponent,
    RoleFormComponent,
    IdatePipe,
    ItimePipe,
    IdatetimePipe,
    SelectAutocompleteComponent,
    ItrMultiSelectComponent,
    OrientDirective,
    AddClassDirective,
    SideModalComponent,
    ItrSelectComponent,
    ItrSelectSingleSearchComponent,
    StopwatchPipe,
    LangSwitcherComponent,
    ItrTableAgComponent,
    AudiotypePipe,
    CarouselComponent,
    CarouselSlideComponent,
    GalleryPreviewComponent,
    StepListComponent,
    ExternalFiltersComponent,
    CustomFilterComponent,
    VideoCallContainerComponent,
    IntentAddmemberComponent,
    PlanCallPreambulaModalComponent,
    CancelEventModalComponent,
    ItorumSelectSearchCustomComponent,
  ],
  exports: [
    ITableModule,
    FilterPipe,
    StatusAndIdFilterPipe,
    RoleFilterPipe,
    ManagerFilterPipe,
    PhonePipe,
    SortPipe,
    ItrLoaderComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ClickOutsideModule,
    NgScrollbarModule,
    GalleryComponent,
    GalleryPreviewComponent,
    RoleFormComponent,
    IdatePipe,
    ItimePipe,
    IdatetimePipe,
    SelectAutocompleteComponent,
    ItrMultiSelectComponent,
    OrientDirective,
    AddClassDirective,
    SideModalComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ItrSelectComponent,
    ItrSelectSingleSearchComponent,
    TranslateModule,
    FieldsModule,
    BsDatepickerModule,
    StopwatchPipe,
    LangSwitcherComponent,
    ItrTableAgComponent,
    AudiotypePipe,
    CarouselComponent,
    CarouselSlideComponent,
    MatCheckboxModule,
    MatRadioModule,
    StepListComponent,
    MatDialogModule,
    CustomFilterComponent,
    VideoCallContainerComponent,
    PlanCallPreambulaModalComponent,
    CancelEventModalComponent,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
