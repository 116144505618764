import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RoomStateStore, RoomStateState } from './room-state.store';
import { Observable } from 'rxjs';
import { IControlChatMessageBody, IFeed } from '@itorum/models';

@Injectable({ providedIn: 'root' })
export class RoomStateQuery extends Query<RoomStateState> {
  constructor(protected store: RoomStateStore) {
    super(store);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая извлекает значение свойства «isWidescreen» из состояния.
   * @returns Возвращается Observable логического типа.
   */
  getIsWidescreen(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isWidescreen);
  }

  getIsVideoAttached(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isVideoAttached);
  }

  /**
   * Эта функция возвращает наблюдаемую часть массива объектов канала.
   * @returns Метод `getFeeds()` возвращает Observable массива объектов, которые реализуют интерфейс `IFeed`. Реализация
   * метода заключается в использовании метода select() для выбора свойства Feeds из объекта состояния. Комментарий
   * `@ts-ignore` подавляет ошибки TypeScript, которые могут возникнуть из-за использования метода `select()`.
   */
  getFeeds(): Observable<IFeed[]> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.feeds);
  }

  /**
   * Эта функция возвращает наблюдаемое логическое значение, указывающее, следует ли показывать ничью.
   * @returns Возвращается Observable логического типа. Значение Observable определяется выбором свойства isShowDraw из
   * объекта состояния.
   */
  isShowDraw(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isShowDraw);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая выдает текущее значение свойства «isNowShared» в состоянии.
   * @returns Возвращается Observable логического типа. Значение Observable получается путем выбора свойства isNowShared из
   * объекта состояния.
   */
  getIsNowShared(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isNowShared);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая создает файл или строку, представляющую изображение.
   * @returns Объект Observable, который создает либо файл, либо строку, в зависимости от состояния свойства «изображение»
   * в текущем состоянии приложения.
   */
  getImage(): Observable<File | string> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.image);
  }

  /**
   * Эта функция возвращает наблюдаемое, которое создает объект File или Blob.
   * @returns Объект Observable, который генерирует объект File или Blob. Значение, испускаемое Observable, получается из
   * свойства «файл» объекта состояния.
   */
  getFile(): Observable<File | Blob> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.file);
  }

  /**
   * Эта функция возвращает наблюдаемое массива объектов сообщения чата из состояния.
   * @returns Объект Observable из массива объектов, реализующих интерфейс IControlChatMessageBody. Данные получаются путем
   * выбора свойства `chatMessages` из состояния.
   */
  getChatMessages(): Observable<IControlChatMessageBody[]> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.chatMessages);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая выбирает URL-адрес снимка экрана запроса из состояния.
   * @returns Возвращается Observable типа string.
   */
  getReqScreenshotUrl(): Observable<string> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.reqScreenshotUrl);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая выдает текущее значение свойства isSideModalOpen из состояния.
   * @returns Возвращается Observable логического типа. Значение Observable определяется путем выбора свойства
   * isSideModalOpen из объекта состояния.
   */
  getIsSideModalOpen(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isSideModalOpen);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая извлекает состояние того, отключены ли все издатели или нет.
   * @returns Возвращается Observable логического типа.
   */
  getIsAllPublishersMuted(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isAllPublishersMuted);
  }

  /**
   * Эта функция возвращает наблюдаемую, которая выбирает состояние «doTerminateWorker» из текущего состояния.
   * @returns Возвращается Observable логического типа. Значение Observable получается путем выбора свойства
   * doTerminateWorker из объекта состояния.
   */
  getDoTerminateWorker(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.doTerminateWorker);
  }

  /**
   * Эта функция возвращает наблюдаемое, которое выдает значение свойства isGuardActive из состояния.
   * @returns Возвращается Observable логического типа. Значение Observable получается путем выбора свойства isGuardActive
   * из объекта состояния.
   */
  getIsGuardActive(): Observable<boolean> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state) => state.isGuardActive);
  }
}
