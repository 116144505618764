import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldSelectComponent } from './select/select.component';
import { FieldInputComponent } from './input/input.component';
import { FieldAutocompleteComponent } from './autocomplete/autocomplete.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

/**
 * @deprecated
 */
@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
    MatAutocompleteModule,
    BsDatepickerModule,
  ],
  exports: [
    FieldSelectComponent,
    FieldInputComponent,
    FieldAutocompleteComponent,
  ],
  declarations: [
    FieldSelectComponent,
    FieldInputComponent,
    FieldAutocompleteComponent,
  ],
})
export class FieldsModule {}
