/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { EventsIdBody } from '../model/eventsIdBody';
import { EventsIcsBody } from '../model/eventsIcsBody';
import { FindCallEventResponse } from '../model/find-call-event-response';
import { CallEvent } from '../model/call-event';
import { BASE_PATH } from '../variables';
import { EventsFindBody } from '../model/eventsFindBody';
import { CallsEventsBody } from '../model/callsEventsBody';
import { Configuration } from '../configuration';
import { PrimeResponse } from '../model/prime-response';

// import {
//   PrimeResponse,
//   CallEvent,
//   FindCallEventResponse,
//   CallsEventsBody,
//   EventsFindBody,
//   EventsIdBody,
//   Configuration,
//   BASE_PATH, EventsIcsBody
// } from "@itorum/api";

@Injectable({
  providedIn: 'root',
})
export class RemoteExpertCallEventsService {
  protected basePath = 'https://dev.itorummr.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Find call events by filter
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2CallsEventsFindPost(
    body?: EventsFindBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PrimeResponse<FindCallEventResponse>>;
  public apiRemoteExpert2CallsEventsFindPost(
    body?: EventsFindBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PrimeResponse<FindCallEventResponse>>>;
  public apiRemoteExpert2CallsEventsFindPost(
    body?: EventsFindBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PrimeResponse<FindCallEventResponse>>>;
  public apiRemoteExpert2CallsEventsFindPost(
    body?: EventsFindBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<PrimeResponse<FindCallEventResponse>>(
      'post',
      `${this.basePath}/api/remote-expert2/calls/events/find`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update call event
   *
   * @param id Call event id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2CallsEventsIdPut(
    id: number,
    body?: EventsIdBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PrimeResponse<CallEvent>>;
  public apiRemoteExpert2CallsEventsIdPut(
    id: number,
    body?: EventsIdBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PrimeResponse<CallEvent>>>;
  public apiRemoteExpert2CallsEventsIdPut(
    id: number,
    body?: EventsIdBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PrimeResponse<CallEvent>>>;
  public apiRemoteExpert2CallsEventsIdPut(
    id: number,
    body?: EventsIdBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiRemoteExpert2CallsEventsIdPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<PrimeResponse<CallEvent>>(
      'put',
      `${this.basePath}/api/remote-expert2/calls/events/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Generate ics file
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2CallsEventsIcsPost(
    body?: EventsIcsBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public apiRemoteExpert2CallsEventsIcsPost(
    body?: EventsIcsBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public apiRemoteExpert2CallsEventsIcsPost(
    body?: EventsIcsBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public apiRemoteExpert2CallsEventsIcsPost(
    body?: EventsIcsBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/pdf'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<string>(
      'post',
      `${this.basePath}/api/remote-expert2/calls/events/ics`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create call event
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemoteExpert2CallsEventsPost(
    body?: CallsEventsBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PrimeResponse<CallEvent>>;
  public apiRemoteExpert2CallsEventsPost(
    body?: CallsEventsBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PrimeResponse<CallEvent>>>;
  public apiRemoteExpert2CallsEventsPost(
    body?: CallsEventsBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PrimeResponse<CallEvent>>>;
  public apiRemoteExpert2CallsEventsPost(
    body?: CallsEventsBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<PrimeResponse<CallEvent>>(
      'post',
      `${this.basePath}/api/remote-expert2/calls/events`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
