import { fullFieldsToChar } from '../utils';

export interface ObjectZoneResponseInterface {
  zone_id: number;
  zone_code: string;
  zone_name: string;
  delete_flag: boolean;
}

export class ObjectZone {
  zone_id: number;
  zone_code: string;
  zone_name: string;
  delete_flag: boolean;

  constructor(obj?: ObjectZoneResponseInterface) {
    if (!obj) {
      return;
    }
    this.zone_id = obj.zone_id;
    this.zone_code = obj.zone_code;
    this.zone_name = obj.zone_name;
    this.delete_flag = obj.delete_flag ? obj.delete_flag : false;
  }

  get object_zone_full() {
    return fullFieldsToChar([this.zone_code, this.zone_name], '/');
  }
}
