import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TagsState, TagsStore } from './tags.store';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TagsQuery extends Query<TagsState> {
  constructor(protected tagsStore: TagsStore) {
    super(tagsStore);
  }

  getTagsState(): Observable<TagsState> {
    console.log('getTagsState =>');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.select((state: TagsState) => {
      return state;
    });
  }
}
