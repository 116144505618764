export class ArrayToggle<T> {
  private _values: T[] = [];

  public get empty(): boolean {
    return this._values.length === 0;
  }

  public get values(): T[] {
    return this._values;
  }

  public set values(data: T[]) {
    if (Array.isArray(data)) {
      this._values = data;
    }
  }

  public toggle(value: T): void {
    if (this.contains(value)) {
      this._values.splice(this._values.indexOf(value), 1);
    } else {
      this._values.push(value);
    }
  }

  public pushUnique(value: T) {
    if (!this.contains(value)) {
      this._values.push(value);
    }
  }

  public contains(value: T): boolean {
    return this._values.indexOf(value) > -1;
  }

  public each(calback) {
    this.values.forEach((item) => {
      calback(item);
    });
  }

  public clear(): ArrayToggle<T> {
    this._values = [];
    return <ArrayToggle<T>>this;
  }
}
