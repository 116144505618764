<div
  class="absolute bg-gray-darkest cursor-move rounded-2xl glow-shadow"
  style="z-index: 1001"
  cdkDrag
  cdkDragBoundary=".screen-boundary"
  [cdkDragFreeDragPosition]="dragPosition"
  [ngStyle]="{
    display: (roomStateQuery.getIsVideoAttached() | async) ? '' : 'none'
  }"
>
  <ng-container [ngSwitch]="display">
    <section *ngSwitchCase="'members'" class="top-menu bg-gray-lightest_plus">
      <ng-container
        *ngTemplateOutlet="menu; context: { mode: display }"
      ></ng-container>
      <article>
        <button
          mat-raised-button
          [color]="'primary'"
          style="outline: none"
          (click)="
            detachModeService.getInstance().isShowInviteUsers =
              !detachModeService.getInstance().isShowInviteUsers;
            detachModeService.getInstance().nowActive = null
          "
        >
          <div class="flex items-center">
            <i class="icon-person_add_alt_1_black text-xl mr-1"></i>
            <span>{{ 'common.add' | translate }}</span>
          </div>
        </button>
      </article>
      <div
        *ngIf="detachModeService?.getInstance()?.isShowInviteUsers"
        class="popover-arrow bg-white pt-4 mr-2.5 rounded border z-50"
        style="width: 250px; position: absolute; right: 0; top: 46px"
      >
        <div
          class="popover-arrow__arrow popover-arrow__arrow--tr"
          style="right: 1.5rem"
        ></div>
        <section
          *ngIf="
            detachModeService?.getInstance()?.isShowInviteUsers &&
            !detachModeService?.getInstance()?.nowActive
          "
        >
          <article
            class="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-superlight"
            (click)="detachModeService.getInstance().nowActive = 'link'"
          >
            <span>{{ 'main.room.invite_by_link' | translate }}</span>
            <i class="icon-link"></i>
          </article>

          <article
            class="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-superlight"
            (click)="detachModeService.getInstance().nowActive = 'list'"
          >
            <span>{{ 'main.room.choose_from_list' | translate }}</span>
            <i class="icon-list"></i>
          </article>
        </section>

        <ng-container [ngSwitch]="detachModeService?.getInstance()?.nowActive">
          <section *ngSwitchCase="'link'">
            <article class="flex-col items-center justify-between p-2">
              <div class="border-b py-2">
                {{ 'main.room.link_info' | translate }}
              </div>
              <div class="underline text-green py-2 break-words">
                {{ link }}
              </div>
            </article>
            <article
              class="flex justify-between w-full px-2 mb-2 border-t pt-3"
            >
              <button
                itorum-button
                class="btn-cancel capitalize-first"
                (click)="detachModeService.getInstance().nowActive = null"
              >
                {{ 'main.room.cancel' | translate }}
              </button>
              <button
                style="padding-right: 1rem; padding-left: 1rem"
                itorum-button
                class="btn-small capitalize-first"
                (click)="
                  clipRead();
                  detachModeService.getInstance().nowActive = null;
                  detachModeService.getInstance().isShowInviteUsers = false
                "
              >
                {{ 'main.room.copy' | translate }}
              </button>
            </article>
          </section>

          <section *ngSwitchCase="'list'" class="px-2 pb-2">
            <itorum-intent-add-member
              *ngFor="let user of detachModeService?.getInstance()?.notInRoom"
              [user]="user"
            ></itorum-intent-add-member>
            <section class="flex flex-row justify-between border-t pt-3 mt-4">
              <button
                itorum-button
                class="btn-cancel mr-2"
                style="width: 45%"
                (click)="
                  detachModeService.getInstance().nowActive = null;
                  detachModeService.getInstance().isShowInviteUsers = false
                "
              >
                {{ 'main.room.cancel' | translate }}
              </button>
              <button
                itorum-button
                class="btn-small capitalize-first"
                (click)="
                  detachModeService.getInstance().nowActive = null;
                  detachModeService.getInstance().isShowInviteUsers = false
                "
              >
                {{ 'main.room.done' | translate }}
              </button>
            </section>
          </section>
        </ng-container>
      </div>
    </section>

    <section *ngSwitchCase="'call'" class="top-menu">
      <ng-container
        *ngTemplateOutlet="menu; context: { mode: display }"
      ></ng-container>
      <!--      <article>-->
      <!--        <i class="icon-arrow-up-left2 text-2xl text-gray cursor-pointer hover:text-black" (click)="returnToRoom()"></i>-->
      <!--      </article>-->
      <article>
        <!--      <i class="icon-arrow-up-left2 text-2xl"></i>-->
      </article>
    </section>

    <section *ngSwitchCase="'messages'" class="top-menu bg-gray-lightest_plus">
      <ng-container
        *ngTemplateOutlet="menu; context: { mode: display }"
      ></ng-container>
      <article>
        <button
          mat-raised-button
          class="flex"
          style="background-color: #eff0f5; outline: none"
          (click)="
            detachModeService.getInstance().isShowLangOptions =
              !detachModeService?.getInstance()?.isShowLangOptions
          "
        >
          <span>
            <span matPrefix class="capitalize-first"
              >{{ 'main.room.translate_to' | translate }} &nbsp;</span
            >
            <ng-container
              [ngSwitch]="detachModeService?.getInstance()?.chatLang"
            >
              <span *ngSwitchCase="'ru'">{{
                'main.room.russian' | translate
              }}</span>
              <span *ngSwitchCase="'sv'">{{
                'main.room.swedish' | translate
              }}</span>
              <span *ngSwitchCase="'es'">{{
                'main.room.spanish' | translate
              }}</span>
              <span *ngSwitchCase="'en'">{{
                'main.room.english' | translate
              }}</span>
              <span *ngSwitchCase="'de'">{{
                'main.room.german' | translate
              }}</span>
              <span *ngSwitchCase="'it'">{{
                'main.room.italian' | translate
              }}</span>
              <span *ngSwitchCase="'zh-CN'">{{
                'main.room.china' | translate
              }}</span>
            </ng-container>
          </span>
          <i class="icon-expand_more_black text-xl pl-2"></i>
        </button>

        <div
          *ngIf="detachModeService?.getInstance()?.isShowLangOptions"
          class="popover-arrow absolute bg-white pt-4 mt-1.5 rounded border z-50"
          style="width: 220px; position: absolute"
        >
          <div class="popover-arrow__arrow popover-arrow__arrow--tr"></div>

          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('ru');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.russian' | translate
            }}</span>
            <i
              *ngIf="detachModeService?.getInstance()?.chatLang === 'ru'"
              class="icon-done_black text-green"
            ></i>
          </div>
          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('sv');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.swedish' | translate
            }}</span>
            <i
              *ngIf="detachModeService.getInstance().chatLang === 'sv'"
              class="icon-done_black text-green"
            ></i>
          </div>
          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('es');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.spanish' | translate
            }}</span>
            <i
              *ngIf="detachModeService?.getInstance()?.chatLang === 'es'"
              class="icon-done_black text-green"
            ></i>
          </div>
          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('en');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.english' | translate
            }}</span>
            <i
              *ngIf="detachModeService?.getInstance()?.chatLang === 'en'"
              class="icon-done_black text-green"
            ></i>
          </div>
          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('de');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.german' | translate
            }}</span>
            <i
              *ngIf="detachModeService?.getInstance()?.chatLang === 'de'"
              class="icon-done_black text-green"
            ></i>
          </div>
          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('it');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.italian' | translate
            }}</span>
            <i
              *ngIf="detachModeService?.getInstance()?.chatLang === 'it'"
              class="icon-done_black text-green"
            ></i>
          </div>
          <div
            class="lang-item"
            (click)="
              detachModeService?.getInstance()?.setChatLanguage('zh-CN');
              detachModeService.getInstance().isShowLangOptions = false
            "
          >
            <span class="capitalize-first">{{
              'main.room.china' | translate
            }}</span>
            <i
              *ngIf="detachModeService?.getInstance()?.chatLang === 'zh-CN'"
              class="icon-done_black text-green"
            ></i>
          </div>
        </div>
      </article>
    </section>
  </ng-container>

  <ng-container [ngSwitch]="display">
    <section
      *ngSwitchCase="'members'"
      class="bg-gray-lightest_plus"
      style="width: 420px; min-height: 150px"
    >
      <ng-container *ngFor="let feed of roomStateQuery.getFeeds() | async">
        <div class="flex flex-row justify-between py-4 border-t border-b px-2">
          <span>
            <i
              class="icon-ondemand_video_black mr-2"
              *ngIf="feed?.video?.hasVideo"
            ></i>
            {{ feed?.rfparsed?.user?.fullName }}
          </span>

          <i
            *ngIf="mutedWatch.get(feed.rfid)?.muted; else mic_on"
            class="icon-mic_off_black hover:text-primary cursor-pointer"
            (click)="toggleMute(feed)"
          ></i>

          <ng-template #mic_on>
            <i
              class="icon-mic_none_black hover:text-primary cursor-pointer"
              (click)="toggleMute(feed)"
            ></i>
          </ng-template>
        </div>
      </ng-container>
    </section>
    <video
      #video_tag
      [ngStyle]="{ height: display !== 'call' ? 0 : '150px' }"
      width="420"
      height="150"
      autoplay
      playsinline
    ></video>
    <div #publishers_container class="publishers-container"></div>
    <section
      *ngSwitchCase="'call'"
      class="flex justify-between items-center absolute px-2"
      style="width: 420px; bottom: 65px"
    >
      <article>
        <button
          mat-mini-fab
          *ngIf="isShowPullers"
          style="background-color: #666666; outline: none"
          aria-label="prev video"
          (click)="prevVideo()"
        >
          <i class="icon-expand_more_black_left text-white"></i>
        </button>
      </article>

      <article>
        <button
          mat-mini-fab
          style="background-color: #666666; outline: none"
          aria-label="turn mic"
          (click)="janusService.toggleMute()"
        >
          <i
            class="icon-mic_off_black text-white"
            *ngIf="janusService.muted | async; else ico_mic"
          ></i>
          <ng-template #ico_mic>
            <i class="icon-mic_none_black text-white"></i>
          </ng-template>
        </button>
        <button
          mat-mini-fab
          id="endCallButton"
          style="
            background-color: #df242b;
            margin: 0 0.5rem 0 0.5rem;
            outline: none;
          "
          aria-label="end call"
          (click)="endCall()"
        >
          <i class="icon-call_end_black text-white"></i>
        </button>
        <button
          mat-mini-fab
          style="background-color: #666666; outline: none"
          aria-label="start translation"
        >
          <i class="icon-present_to_all_black text-white"></i>
        </button>
      </article>

      <article>
        <button
          mat-mini-fab
          *ngIf="isShowPullers"
          style="background-color: #666666; outline: none"
          aria-label="next video"
          (click)="nextVideo()"
        >
          <i class="icon-expand_more_black_right text-white"></i>
        </button>
      </article>
    </section>

    <!--         *ngIf="roomStateQuery.getIsVideoAttached() | async"-->

    <section
      *ngSwitchCase="'messages'"
      class="bg-gray-lightest_plus"
      style="width: 420px; min-height: 150px"
    >
      <section
        style="height: 65vh"
        class="flex flex-col-reverse justify-start overflow-y-scroll"
      >
        <div
          class="my-1 px-2"
          *ngFor="
            let control of detachModeService.getInstance()?.chatMessagesReverse;
            trackBy: trackByChatId
          "
        >
          <section class="flex flex-row justify-between py-1">
            <span class="text-green font-bold">{{
              control?.from?.fullName
            }}</span>
            <span class="text-gray font-medium">{{
              control?.timestamp | itime
            }}</span>
          </section>
          <section class="py-1">
            <ng-container [ngSwitch]="control?.type">
              <div
                *ngSwitchCase="'text'"
                class="text-black font-normal"
                style="overflow-wrap: anywhere"
              >
                {{ control?.data?.text }}
              </div>
              <div *ngSwitchCase="'media'">
                <img
                  [src]="control?.data?.url"
                  alt="img link"
                  (click)="openImage(control?.data?.url)"
                  style="display: block; width: 100%"
                />
              </div>
            </ng-container>
          </section>
        </div>
      </section>
      <section
        *ngIf="!isShowVoiceRecordPanel; else voiceRecordPanel"
        class="bg-gray-lightest_plus w-100 slime-input pb-1 px-1"
      >
        <mat-form-field appearance="outline" class="w-full">
          <!--                    [rows]="dynamicRows"-->
          <input
            #chat_input
            style="overflow: hidden; resize: none"
            matInput
            type="search"
            [maxLength]="250"
            (keydown.enter)="sendChatMessage(chat_input.value)"
            [placeholder]="'main.room.chat_placeholder' | translate"
          />
          <i
            matSuffix
            *ngIf="chat_input.value"
            class="icon-chat_send text-green font-bold text-lg cursor-pointer"
            (click)="sendChatMessage(chat_input.value)"
          ></i>
          <i
            matSuffix
            *ngIf="!chat_input.value"
            class="icon-mic_none_black text-green font-bold text-lg cursor-pointer ml-4"
            (click)="startVoiceRecord(); isShowVoiceRecordPanel = true"
          ></i>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let _ of empty" [value]="1">
              {{ _ }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </section>
    </section>
    <ng-template #voiceRecordPanel>
      <section class="border-t border-b pb-6 pt-4 px-4">
        <div class="flex justify-between">
          <i
            class="icon-cross_clear_black text-red-600 font-bold text-lg cursor-pointer"
            (click)="cancelVoiceRecord(); isShowVoiceRecordPanel = false"
          ></i>
          <span
            >{{ 'main.room.record' | translate }} :
            {{
              detachModeService?.getInstance()?.voiceSampleLength | stopwatch
            }}</span
          >
          <i
            class="icon-done_black text-green font-bold text-lg cursor-pointer"
            (click)="submitVoiceRecord(); isShowVoiceRecordPanel = false"
          ></i>
        </div>
      </section>
    </ng-template>
  </ng-container>

  <section class="flex items-center">
    <article
      class="nav-panel rounded-bl-2xl"
      [ngClass]="{ 'nav-panel_active': display === 'members' }"
      style="height: 60px"
      (click)="display = 'members'; isMenuExpanded = false"
    >
      <i class="block icon-people_black_24dp"></i>
      <div>
        {{ 'shared.video-call-container.members' | translate }} ({{
          detachModeService.getInstance()?.stateFeeds?.length || 0
        }})
      </div>
    </article>
    <article
      class="nav-panel"
      style="height: 60px"
      [ngClass]="{ 'nav-panel_active': display === 'call' }"
      (click)="display = 'call'; isMenuExpanded = false"
    >
      <i class="block icon-phone_in_talk"></i>
      <div>{{ 'shared.video-call-container.call' | translate }}</div>
    </article>
    <article
      class="nav-panel rounded-br-2xl"
      style="height: 60px"
      [ngClass]="{ 'nav-panel_active': display === 'messages' }"
      (click)="display = 'messages'; isMenuExpanded = false"
    >
      <i class="block icon-question_answer"></i>
      <div>
        {{ 'shared.video-call-container.messages' | translate }} ({{
          detachModeService.getInstance()?.chatMessagesReverse?.length || 0
        }})
      </div>
    </article>
  </section>
</div>

<ng-template #menu let-mode="mode">
  <article>
    <i
      class="icon-pending_open_menu drop-menu-button"
      [ngClass]="{ 'drop-menu-button-inverse': mode !== 'call' }"
      (click)="isMenuExpanded = !isMenuExpanded"
    ></i>
  </article>
  <div class="popover-arrow drop-menu" *ngIf="isMenuExpanded">
    <div class="popover-arrow__arrow popover-arrow__arrow--tl"></div>
    <!--    <div class="drop-menu-item">{{mode}}</div>-->
    <div
      class="drop-menu-item mt-2"
      (click)="returnToRoom(); isMenuExpanded = false"
    >
      <i class="icon-display_external_input text-xl pr-1"></i>
      <span>{{
        'shared.video-call-container.to_full_screen_mode' | translate
      }}</span>
    </div>
  </div>
</ng-template>
