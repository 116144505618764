import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stopwatch',
})
export class StopwatchPipe implements PipeTransform {
  transform(value: string | number, args?: any): string {
    let secondsTotal: number =
      typeof value === 'string' ? parseInt(value || '0', 10) : value;
    if (args) {
      secondsTotal = Math.trunc(secondsTotal);
    }
    const minute_part = Math.trunc(secondsTotal / 60);
    const seconds_part = secondsTotal % 60;
    return `${minute_part > 9 ? minute_part : `0${minute_part}`}:${
      seconds_part > 9 ? seconds_part : `0${seconds_part}`
    }`;
  }
}
