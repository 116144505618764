import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastPackage, ToastrService } from 'ngx-toastr';
import { JanusService } from '@src/app/common/services/janus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itorum-toastr-call-dialog',
  templateUrl: './toastr-call-dialog.component.html',
  styleUrls: ['./toastr-call-dialog.component.scss'],
  // animations: [
  //   trigger('flyInOut', [
  //     state('inactive', style({ opacity: 0 })),
  //     state('active', style({ opacity: 1 })),
  //     state('removed', style({ opacity: 0 })),
  //     transition(
  //       'inactive => active',
  //       animate('{{ easeTime }}ms {{ easing }}')
  //     ),
  //     transition(
  //       'active => removed',
  //       animate('{{ easeTime }}ms {{ easing }}')
  //     )
  //   ])
  // ],
})
export class ToastrCallDialogComponent implements OnInit, OnDestroy {
  private destroy$: Subject<any> = new Subject();
  timeout: any;
  text: any;
  title: any;

  // /** controls animation */
  // @HostBinding('@flyInOut')
  // state = {
  //   value: 'inactive',
  //   params: {
  //     easeTime: this.toastPackage.config.easeTime,
  //     easing: 'ease-in'
  //   }
  // };
  private janus_msg: any;
  private sfutest: any;
  private join: () => void;
  private bell: HTMLAudioElement;
  private janusService: JanusService;

  constructor(
    private router: Router,
    protected toastrService: ToastrService,
    // private janusService: JanusService,
    public toastPackage: ToastPackage,
    protected ngZone?: NgZone
  ) {
    this.text = toastPackage.message;
    this.title = toastPackage.title;
  }

  ngOnInit(): void {
    // console.log('=== ngOnInit ToastrCallDialogComponent ===');
  }

  ngOnDestroy(): void {
    // console.log('=== ngOnDestroy ToastrCallDialogComponent ===');
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  outsideTimeout(func: () => any, timeout: number) {
    if (this.ngZone) {
      this.ngZone.runOutsideAngular(
        () =>
          (this.timeout = setTimeout(
            () => this.runInsideAngular(func),
            timeout
          ))
      );
    } else {
      this.timeout = setTimeout(() => func(), timeout);
    }
  }

  private runInsideAngular(func: () => any) {
    if (this.ngZone) {
      this.ngZone.run(() => func());
    } else {
      func();
    }
  }

  /**
   * set janus objects to respond out
   * @param msg
   * @param sfutest
   * @param join {Function}
   */
  setData(msg, sfutest, join, bell, janusInstance) {
    this.janusService = janusInstance;
    this.janus_msg = msg;
    this.sfutest = sfutest;
    this.join = join;
    this.bell = bell;
    this.sendIncomingCallAck(msg);

    this.janusService.onIncomingCallCancelled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((answer) => {
        // console.log('onIncomingCallCancelled$ =>', answer);
        if (answer) {
          this.remove();
        }
      });
  }

  private sendIncomingCallAck = (message) => {
    console.log('========sendCallAck======', message);
    const callAck = {
      control: 'callAck',
      uid: message.uid,
    };

    this.sfutest.send({ message: callAck });
  };

  private onAcceptCall(message) {
    this.bell.pause();
    // console.log('========callAccept======', message);
    const callAccept = {
      control: 'callAccept',
      uid: message.uid,
    };

    if (this.router.url.includes('main/conference/room')) {
      this.router
        .navigateByUrl(`/main/conference/conference-list`)
        .then((answer) => {
          // if (answer) {
          this.sfutest.send({ message: callAccept });
          // }
        })
        .catch((e) => console.error(e));
      // this.router.navigateByUrl(`/main/conference/room/accepted_call`);
    } else {
      this.sfutest.send({ message: callAccept });
    }
  }

  private onRejectCall(message) {
    this.bell.pause();
    console.log('========callReject======', message);
    const callReject = {
      control: 'callReject',
      uid: message.uid,
    };

    this.sfutest.send({ message: callReject });
  }

  cancel() {
    this.onRejectCall(this.janus_msg);
    this.remove();
  }

  okay() {
    this.onAcceptCall(this.janus_msg);
    this.remove();
  }

  /**
   * tells toastrService to remove this toast after animation time
   */
  remove() {
    // if (this.state.value === 'removed') {
    //   return;
    // }
    clearTimeout(this.timeout);
    // this.state = { ...this.state, value: 'removed' };
    this.outsideTimeout(
      () => this.toastrService.remove(this.toastPackage.toastId),
      +this.toastPackage.config.easeTime
    );
  }
}
