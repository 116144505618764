/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ActiontemplateFindBody } from '../model/actiontemplateFindBody';
import { ActiontemplateIdBody } from '../model/actiontemplateIdBody';
import { ApiActiontemplateBody } from '../model/apiActiontemplateBody';
import { IdAssignstepBody } from '../model/idAssignstepBody';
import { IdChangeorderstepBody } from '../model/idChangeorderstepBody';
import { InlineResponse20030 } from '../model/inlineResponse20030';
import { InlineResponse20031 } from '../model/inlineResponse20031';
import { InlineResponse20032 } from '../model/inlineResponse20032';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { TransitionResponce } from '../model/transitionResponce';

@Injectable({
  providedIn: 'root',
})
export class ActionTemplateService {
  protected basePath = 'https://dev.itorum-mr.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Find action templates
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateFindPost(
    body?: ActiontemplateFindBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20031>;
  public apiActionTemplateFindPost(
    body?: ActiontemplateFindBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20031>>;
  public apiActionTemplateFindPost(
    body?: ActiontemplateFindBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20031>>;
  public apiActionTemplateFindPost(
    body?: ActiontemplateFindBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20031>(
      'post',
      `${this.basePath}/api/action-template/find`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Assign step
   *
   * @param id Action template id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateIdAssignStepPut(
    id: number,
    body?: IdAssignstepBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiActionTemplateIdAssignStepPut(
    id: number,
    body?: IdAssignstepBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiActionTemplateIdAssignStepPut(
    id: number,
    body?: IdAssignstepBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiActionTemplateIdAssignStepPut(
    id: number,
    body?: IdAssignstepBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiActionTemplateIdAssignStepPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/action-template/${encodeURIComponent(
        String(id)
      )}/assign-step`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Change orderNum step
   *
   * @param id Action template id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateIdChangeOrderStepPut(
    id: number,
    body?: IdChangeorderstepBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public apiActionTemplateIdChangeOrderStepPut(
    id: number,
    body?: IdChangeorderstepBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public apiActionTemplateIdChangeOrderStepPut(
    id: number,
    body?: IdChangeorderstepBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public apiActionTemplateIdChangeOrderStepPut(
    id: number,
    body?: IdChangeorderstepBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiActionTemplateIdChangeOrderStepPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/api/action-template/${encodeURIComponent(
        String(id)
      )}/change-order-step`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Copy action template
   *
   * @param id Action template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateIdCopyGet(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20030>;
  public apiActionTemplateIdCopyGet(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20030>>;
  public apiActionTemplateIdCopyGet(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20030>>;
  public apiActionTemplateIdCopyGet(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiActionTemplateIdCopyGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20030>(
      'get',
      `${this.basePath}/api/action-template/${encodeURIComponent(
        String(id)
      )}/copy`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update action template
   *
   * @param id Action template id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateIdPut(
    id: number,
    body?: ActiontemplateIdBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20030>;
  public apiActionTemplateIdPut(
    id: number,
    body?: ActiontemplateIdBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20030>>;
  public apiActionTemplateIdPut(
    id: number,
    body?: ActiontemplateIdBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20030>>;
  public apiActionTemplateIdPut(
    id: number,
    body?: ActiontemplateIdBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiActionTemplateIdPut.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20030>(
      'put',
      `${this.basePath}/api/action-template/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Step Transitions By Action template Id
   *
   * @param id Action template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateIdStepTransitionGet(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TransitionResponce>;
  public apiActionTemplateIdStepTransitionGet(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TransitionResponce>>;
  public apiActionTemplateIdStepTransitionGet(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TransitionResponce>>;
  public apiActionTemplateIdStepTransitionGet(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling apiActionTemplateIdStepTransitionGet.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<TransitionResponce>(
      'get',
      `${this.basePath}/api/action-template/${encodeURIComponent(
        String(id)
      )}/step-transition`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create action template
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplatePost(
    body?: ApiActiontemplateBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20030>;
  public apiActionTemplatePost(
    body?: ApiActiontemplateBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20030>>;
  public apiActionTemplatePost(
    body?: ApiActiontemplateBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20030>>;
  public apiActionTemplatePost(
    body?: ApiActiontemplateBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20030>(
      'post',
      `${this.basePath}/api/action-template`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Translate action template
   *
   * @param body Object containing actionTemplateId, langFrom, and langTo
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiActionTemplateTranslatePost(
    body: { actionTemplateId: number; langFrom: string; langTo: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InlineResponse20030>;
  public apiActionTemplateTranslatePost(
    body: { actionTemplateId: number; langFrom: string; langTo: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InlineResponse20030>>;
  public apiActionTemplateTranslatePost(
    body: { actionTemplateId: number; langFrom: string; langTo: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InlineResponse20030>>;
  public apiActionTemplateTranslatePost(
    body: { actionTemplateId: number; langFrom: string; langTo: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling apiActionTemplateTranslatePost.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20030>(
      'post',
      `${this.basePath}/api/action-template/translate`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
